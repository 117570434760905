import styled from "styled-components";
import {
  space,
  layout,
  color,
  typography,
  position,
  border,
  flexbox,
} from "styled-system";
import { HTMLAttributes } from "react";
import createShouldForwardProp from "../../../utils/createShouldForwardProp";

export const Flex = styled.div.withConfig({
  shouldForwardProp: createShouldForwardProp<HTMLAttributes<HTMLDivElement>>(
    "color",
    "display",
    "gap"
  ),
})`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${flexbox}
  ${({ gap }: { gap?: string }) => (gap ? `gap: ${gap}` : "")}
`;
