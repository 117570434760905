import { Routing } from "../../constants/routing";
import { Navigate, Outlet } from "react-router-dom";
import * as React from "react";

import { useAuthContext } from "../../contexts/AuthContext";
import { useTeams } from "../../api/modules/Teams";
import { Splash } from "../../containers/Splash";
import Flex from "../LayoutComponents/Flex";

export const ProtectedRoutes = (): React.ReactElement | null => {
  const { isAuthorized } = useAuthContext();
  const { isFetched } = useTeams();
  if (!isAuthorized) {
    return <Navigate to={Routing.Login} />;
  }

  if (!isFetched) {
    return (
      <Flex height="100vh">
        <Splash uniqueId="initial" height="300px" />
      </Flex>
    );
  }

  return <Outlet />;
};
