import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { AddUserFormType } from "./AddUserForm.types";
import { isSuccessResponse } from "../../../../types/common/Api";
import { useAddUser } from "../../../../api/modules/AddUser";
import { AddUserFormPresentation } from "./AddUserForm.presentation";
import { Routing } from "../../../../constants/routing";
import { useNavigate } from "react-router-dom";

export const AddUserForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm<AddUserFormType>({
    mode: "onChange",
  });
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const { mutateAsync, isLoading } = useAddUser();
  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        email: data.email,
        role: data.role,
      },
      {
        onSuccess: (res) => {
          if (!isSuccessResponse(res)) {
            setFormErrorMessage(res.messages?.[0] || "Error adding a user");
            return;
          }
          navigate(Routing.Users);
        },
      }
    );
  });
  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  return (
    <AddUserFormPresentation
      touchedFields={touchedFields}
      errors={errors}
      register={register}
      isValid={isValid}
      isLoading={isLoading}
      formErrorMessage={formErrorMessage}
      onChangeAnyField={handleChangeAnyField}
      onSubmit={onSubmit}
    />
  );
};
