import Form from "../../../../components/FormComponents/Form";
import Input from "../../../../components/FormComponents/Input";
import Flex from "../../../../components/LayoutComponents/Flex";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../../../components/FormComponents/Button";
import { PresentationProps } from "./CreateSubscriptionForm.types";

export const CreateSubscriptionFormPresentation = (
  props: PresentationProps
) => {
  const {
    onSubmit,
    touchedFields,
    errors,
    register,
    onChangeAnyField,
    isValid,
    isLoading,
    formErrorMessage,
  } = props;
  return (
    <Form flexDirection="column" flexGrow={1} width="100%" onSubmit={onSubmit}>
      <Flex
        alignItems="flex-start"
        alignSelf="center"
        flexDirection="column"
        flexGrow={1}
        width="100%"
      >
        <Input
          id="numberOfSeats"
          label="number of seats"
          isTouched={touchedFields.numberOfSeats}
          type="number"
          min={1}
          errorMessage={errors.numberOfSeats?.message}
          {...register("numberOfSeats", {
            onChange: onChangeAnyField,
            required: true,
            min: 1,
          })}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="center" mt={7}>
        <Button
          disabled={!isValid}
          isFullWidth={true}
          type="submit"
          variant={ButtonVariants.Primary}
          variantState={
            isLoading
              ? ButtonVariantStates.Loading
              : formErrorMessage
              ? ButtonVariantStates.Error
              : ButtonVariantStates.Idle
          }
        >
          {formErrorMessage || "create subscription"}
        </Button>
      </Flex>
    </Form>
  );
};
