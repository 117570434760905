import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TResponse, TChangeRoleBody } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";
import { Queries } from "../../common/queries";

export const useChangeRole = () => {
  const { addToast } = useToasts();
  const handleUpdateUserRole = useRequestHandler();
  const queryClient = useQueryClient();

  return useMutation<TResponse, unknown, TChangeRoleBody>(
    Mutations.UpdateUserRole,
    (payload) => handleUpdateUserRole(payload),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient
            .invalidateQueries(Queries.TeamMembers)
            .catch((e) => console.error(e));
          return;
        }
        addToast("Error updating a team member", {
          appearance: "error",
        });
      },
    }
  );
};
