import { Outlet } from "react-router-dom";
import Flex from "../LayoutComponents/Flex";
import Navigation from "../Navigation";
import SideMenu from "../SideMenu";
import { Footer } from "../Footer";

export const Layout = () => (
  <Flex
    alignItems="center"
    margin="0 auto"
    flexDirection="column"
    minHeight="100vh"
    maxWidth="900px"
    width="100%"
    px={[3, 3, 3, 0]}
    pb={[4, 4, 4, 0]}
  >
    <Navigation />
    <Flex
      justifyContent="center"
      flex={1}
      mt={5}
      width="100%"
      gap="20px"
      alignItems="flex-start"
      flexDirection={["column", "column", "row", "row"]}
    >
      <SideMenu />
      <Flex
        flexGrow={1}
        flexDirection="column"
        width={["100%", "100%", "auto", "auto"]}
        alignItems="flex-start"
      >
        <Outlet />
      </Flex>
    </Flex>
    <Footer />
  </Flex>
);
