import { PresentationProps } from "./VerifyEmail.types";
import Panel from "../../components/Panel";
import Text from "../../components/TypographyComponents/Text";
import * as S from "./VerifyEmail.styles";
import { Button, ButtonVariants } from "../../components/FormComponents/Button";
import Flex from "../../components/LayoutComponents/Flex";
import { VariantStates } from "../../components/FormComponents/Button/Button";

export const VerifyEmailPresentation = (props: PresentationProps) => {
  const { onSendEmail, isLoading } = props;
  return (
    <S.Wrapper
      justifyContent="center"
      minHeight="100vh"
      alignItems="center"
      flexDirection="column"
    >
      <Panel alignItems="center" flexDirection="column" mb={5} maxWidth="500px">
        <Text.H2>Please verify your email address</Text.H2>
        <Flex my={6} textAlign="center">
          <Text.Body3>
            To access team management app you need to verify your email address.
            By clicking the button below, the verification code will be send and
            you'll be taken to the verification page.
          </Text.Body3>
        </Flex>
        <Button
          variant={ButtonVariants.Primary}
          variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
          onClick={onSendEmail}
        >
          Verify
        </Button>
      </Panel>
    </S.Wrapper>
  );
};
