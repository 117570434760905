import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { AddDomainFormType } from "./ManageDomains.types";
import { isSuccessResponse } from "../../../../types/common/Api";
import { ManageDomainsPresentation } from "./ManageDomains.presentation";
import { useAddDomain } from "../../../../api/modules/AddDomain";
import { useTeams } from "../../../../api/modules/Teams";
import { useRemoveDomain } from "../../../../api/modules/RemoveDomain";

export const ManageDomains = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, touchedFields },
  } = useForm<AddDomainFormType>({
    mode: "onChange",
  });
  const { data: teamsData } = useTeams();
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const { mutateAsync, isLoading: isAdding } = useAddDomain();
  const { mutateAsync: handleRequestRemoveDomain, isLoading: isRemoving } =
    useRemoveDomain();
  const handleRemoveDomain = useCallback(
    (id: string) => async () => {
      await handleRequestRemoveDomain(id);
    },
    [handleRequestRemoveDomain]
  );
  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        domain: data.domain,
      },
      {
        onSuccess: (res) => {
          if (!isSuccessResponse(res)) {
            setFormErrorMessage(res.messages?.[0] || "Error adding domain");
            return;
          }
          reset();
        },
      }
    );
  });
  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  return (
    <ManageDomainsPresentation
      list={teamsData?.[0]?.teamDomains}
      touchedFields={touchedFields}
      errors={errors}
      register={register}
      isValid={isValid}
      isLoading={isAdding || isRemoving}
      formErrorMessage={formErrorMessage}
      onChangeAnyField={handleChangeAnyField}
      onSubmit={onSubmit}
      onRemoveDomain={handleRemoveDomain}
    />
  );
};
