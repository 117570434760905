import { SELF_URL } from "../../../../../constants/global";
import { Routing } from "../../../../../constants/routing";

export const getCheckoutUrls = () => ({
  successUrl: `${SELF_URL}${Routing.PaymentSuccess}`,
  cancelUrl: `${SELF_URL}${Routing.PaymentError}`,
});

export const getManageBillingUrls = () => ({
  redirectUrl: SELF_URL,
});
