import { useCallback } from "react";

import { useDelete } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TResponse } from "./types";
import { useUser } from "../User";
import { useTeams } from "../Teams";

export const useRequestHandler = () => {
  const del = useDelete<{}, TResponse>();
  const { data: userData } = useUser();
  const { data: teams } = useTeams();

  return useCallback(
    async (domainId: string) => {
      return del({
        path: Endpoints.deleteDomain(
          userData?.user?.id || "",
          teams?.[0]?.id || "",
          domainId
        ),
        body: {},
        options: {
          apiVersion: 3,
        },
      });
    },
    [del]
  );
};
