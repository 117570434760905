export const Routing = {
  Root: "/",
  Login: "/login",
  Payments: "/payments",
  Users: "/users",
  AddUser: "/users/add",
  PaymentSuccess: "/paymentSuccess",
  PaymentError: "/paymentError",
  Verify: "/verify",
};
