import { useCallback } from "react";

import { usePost } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TAppleSignInBody, TResponse } from "./types";

export const useRequestHandler = () => {
  const post = usePost<TAppleSignInBody, TResponse>();

  return useCallback(
    async (payload: TAppleSignInBody) => {
      return post({
        path: Endpoints.postAppleSignIn,
        body: {
          ...payload,
        },
      });
    },
    [post]
  );
};
