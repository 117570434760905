import { ReactNode, useMemo } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import Box from "../../../LayoutComponents/Box";
import * as S from "./SideMenuLink.styles";

export const SideMenuLink = ({
  children,
  href,
  isDisabled,
  target,
  to,
  onClick,
  ...props
}: Partial<NavLinkProps> & {
  isDisabled?: boolean;
  href?: string;
  children: ReactNode;
}) => {
  const activeStyle = useMemo(
    () => ({
      opacity: 1,
    }),
    []
  );

  return (
    <Box my={3}>
      <S.LinkWrapper isDisabled={isDisabled}>
        {href && (
          <a href={href} target={target}>
            {children}
          </a>
        )}
        {to && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : {})}
            target={target}
            to={to}
            {...props}
          >
            {children}
          </NavLink>
        )}
        {!href && !to && (
          <a style={{ cursor: "pointer" }} onClick={onClick}>
            {children}
          </a>
        )}
      </S.LinkWrapper>
    </Box>
  );
};
