import { useQuery } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { Queries } from "../../common/queries";
import { TDetailedSubscription } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { useUser } from "../User";
import { useTeams } from "../Teams";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { WEB_APP_URL } from "../../../constants/global";

export const useSubscriptions = () => {
  const { addToast } = useToasts();
  const { data: teams } = useTeams();

  const handleGetSubscriptions = useRequestHandler();
  const { data: userData } = useUser();

  return useQuery<TDetailedSubscription[]>(
    Queries.Subscriptions,
    handleGetSubscriptions(teams?.[0]?.id || ""),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: (err) => {
        // if user belongs to a team and do not have permissions to see users, then he should be redirected to web-app
        if (getErrorMessage(err).includes("not authorized")) {
          window.location.replace(WEB_APP_URL);
        }
        addToast("Error getting team subscriptions", {
          appearance: "error",
        });
      },
      enabled: Boolean(userData?.user?.id) && Boolean(teams?.[0]?.id),
    }
  );
};
