import { useCallback } from "react";

import { usePost } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TCreateSubscriptionBody, TResponse } from "./types";
import { useUser } from "../User";
import { useTeams } from "../Teams";

export const useRequestHandler = () => {
  const post = usePost<TCreateSubscriptionBody, TResponse>();
  const { data: userData } = useUser();
  const { data: teams } = useTeams();

  return useCallback(
    async (payload: TCreateSubscriptionBody) => {
      return post({
        path: Endpoints.postCreateSubscription(
          userData?.user?.id || "",
          teams?.[0]?.id || ""
        ),
        body: {
          ...payload,
        },
        options: {
          apiVersion: 3,
        },
      });
    },
    [post]
  );
};
