import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { BulkAddUsersFormType } from "./BulkAddUsersForm.types";
import { isSuccessResponse } from "../../../../types/common/Api";
import { BulkAddUsersFormPresentation } from "./BulkAddUsersForm.presentation";
import { Routing } from "../../../../constants/routing";
import { useNavigate } from "react-router-dom";
import { useBulkAddUsers } from "../../../../api/modules/BulkAddUsers";

export const BulkAddUsersForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm<BulkAddUsersFormType>({
    mode: "onChange",
  });
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const { mutateAsync, isLoading } = useBulkAddUsers();
  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        emailsRawString: data.emailsRawString,
        role: data.role,
      },
      {
        onSuccess: (res) => {
          if (!isSuccessResponse(res)) {
            setFormErrorMessage(res.messages?.[0] || "Error bulk adding users");
            return;
          }
          navigate(Routing.Users);
        },
      }
    );
  });
  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  return (
    <BulkAddUsersFormPresentation
      touchedFields={touchedFields}
      errors={errors}
      register={register}
      isValid={isValid}
      isLoading={isLoading}
      formErrorMessage={formErrorMessage}
      onChangeAnyField={handleChangeAnyField}
      onSubmit={onSubmit}
    />
  );
};
