import { PaymentSuccessPresentation } from "./PaymentSuccess.presentation";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const handleDismiss = useCallback(() => {
    navigate(Routing.Root);
  }, [navigate]);

  return <PaymentSuccessPresentation onDismiss={handleDismiss} />;
};
