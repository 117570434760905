import { Routing } from "../../constants/routing";
import {
  HiOutlineUserGroup as UserManagementIcon,
  HiOutlineBriefcase as TeamIcon,
  HiOutlineSupport as SupportIcon,
} from "react-icons/hi";
import { AiOutlineLogin as LogoutIcon } from "react-icons/ai";
import { RiSecurePaymentLine as PaymentIcon } from "react-icons/ri";
import { SideMenuLink } from "./components/SideMenuLink/SideMenuLink";
import Panel from "../Panel";
import Flex from "../LayoutComponents/Flex";
import { useTeams } from "../../api/modules/Teams";
import { CUSTOMER_SUPPORT_EMAIL } from "../../constants/global";
import { useLogoutUser } from "../../api/modules/LogoutUser";
import Text from "../TypographyComponents/Text";
import { useHasPermission } from "../../hooks/useHasPermission";
import { Permissions } from "../../types/common/Permissions";

export const SideMenu = () => {
  const { data } = useTeams();
  const getHasPermission = useHasPermission();
  const hasPermissionUserManagement = getHasPermission(
    Permissions.UserManagement
  );
  const handleLogout = useLogoutUser();

  const onLogout = () => {
    const isConfirmed = window.confirm(
      "You are about to log out. Are you sure?"
    );
    if (!isConfirmed) return;
    handleLogout();
  };
  const hasTeams = Array.isArray(data) && data.length;

  return (
    <Panel
      width={["100%", "100%", "230px", "230px"]}
      flexDirection="column"
      minHeight="auto"
      flexShrink={0}
      flexGrow={0}
      py={4}
    >
      <SideMenuLink to={Routing.Root}>
        <Flex alignItems="center">
          <TeamIcon fontSize="20px" />
          <Flex ml={3}>
            <Text.Body2>my team</Text.Body2>
          </Flex>
        </Flex>
      </SideMenuLink>
      {hasPermissionUserManagement && (
        <SideMenuLink to={Routing.Users} isDisabled={!hasTeams}>
          <Flex alignItems="center">
            <UserManagementIcon fontSize="20px" />
            <Flex ml={3}>
              <Text.Body2>user management</Text.Body2>
            </Flex>
          </Flex>
        </SideMenuLink>
      )}
      <SideMenuLink href={CUSTOMER_SUPPORT_EMAIL}>
        <Flex alignItems="center">
          <SupportIcon fontSize="20px" />
          <Flex ml={3}>
            <Text.Body2>contact support</Text.Body2>
          </Flex>
        </Flex>
      </SideMenuLink>
      <SideMenuLink onClick={onLogout}>
        <Flex alignItems="center">
          <LogoutIcon fontSize="20px" />
          <Flex ml={3}>
            <Text.Body2>logout</Text.Body2>
          </Flex>
        </Flex>
      </SideMenuLink>
    </Panel>
  );
};
