import Form from "../../../../components/FormComponents/Form";
import Input from "../../../../components/FormComponents/Input";
import Flex from "../../../../components/LayoutComponents/Flex";
import Text from "../../../../components/TypographyComponents/Text";
import { FiTrash as RemoveIcon } from "react-icons/fi";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../../../components/FormComponents/Button";
import { PresentationProps } from "./ManageDomains.types";
import { domainPattern } from "../../../../utils/emailPattern";

export const ManageDomainsPresentation = (props: PresentationProps) => {
  const {
    onSubmit,
    onRemoveDomain,
    touchedFields,
    errors,
    register,
    onChangeAnyField,
    isValid,
    isLoading,
    formErrorMessage,
    list,
  } = props;
  return (
    <Flex flexDirection="column">
      <Flex mt={3} flexDirection="column">
        {Boolean(list?.length) && (
          <Text.Body3
            fontWeight={300}
            color="white"
            letterSpacing="3px"
            textTransform="uppercase"
          >
            Current Domains:
          </Text.Body3>
        )}
        {(list || []).map(({ id, domain }) => (
          <Flex key={id} gap="5px" my={1} alignItems="center">
            <Text.Body2>• {domain}</Text.Body2>
            <Flex>
              <RemoveIcon
                color="white"
                cursor="pointer"
                onClick={onRemoveDomain(id)}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex my={3}>
        <Form
          flexDirection="column"
          flexGrow={1}
          width="100%"
          onSubmit={onSubmit}
        >
          <Flex
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            width="100%"
          >
            <Input
              id="domain"
              label="Add New Domain:"
              isTouched={touchedFields.domain}
              errorMessage={errors.domain?.message}
              {...register("domain", {
                onChange: onChangeAnyField,
                required: true,
                pattern: {
                  value: domainPattern,
                  message: "Domain is not correct",
                },
              })}
            />
          </Flex>
          <Flex mt={3}>
            <Button
              disabled={!isValid || isLoading}
              type="submit"
              variant={ButtonVariants.Primary}
              variantState={
                isLoading
                  ? ButtonVariantStates.Loading
                  : formErrorMessage
                  ? ButtonVariantStates.Error
                  : ButtonVariantStates.Idle
              }
            >
              {formErrorMessage || "Add domain"}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};
