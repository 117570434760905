import { UserManagementPresentation } from "./UserManagement.presentation";
import { useTeamMembers } from "../../api/modules/TeamMembers";
import { useDeleteUser } from "../../api/modules/DeleteUser";
import { useTransferOwnership } from "../../api/modules/TransferOwnership";
import { useChangeRole } from "../../api/modules/ChangeRole";
import {
  TPendingTeamMember,
  TTeamMember,
  UserRoles,
} from "../../api/modules/TeamMembers/types";
import { useUserRole } from "../../hooks/useUserRole";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useUser } from "../../api/modules/User";

export const UserManagement = () => {
  const {
    data: teamMembersData,
    isLoading: isLoadingMembers,
    isFetching,
  } = useTeamMembers();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPendingQuery, setSearchPendingQuery] = useState("");
  const { mutateAsync: handleRemoveUser, isLoading: isDeletingUser } =
    useDeleteUser();
  const {
    mutateAsync: handleTransferOwnership,
    isLoading: isTransferringOwnership,
  } = useTransferOwnership();
  const { mutateAsync: handleChangeRole, isLoading: isChangingRole } =
    useChangeRole();
  const isLoadingUserAction =
    isDeletingUser || isTransferringOwnership || isChangingRole || isFetching;
  const currentUserRole = useUserRole();
  const { data: userData } = useUser();
  const onRemoveUser = (email: string) => () => {
    const isConfirmed = window.confirm(
      "You are about to remove user. Are you sure?"
    );
    if (!isConfirmed) return;
    handleRemoveUser({ email }).catch((e) => console.error(e));
  };
  const onChangeRole = (email: string) => (role: UserRoles) => () => {
    const isConfirmed = window.confirm(
      "You are about to change user's role. Are you sure?"
    );
    if (!isConfirmed) return;
    handleChangeRole({ email, role }).catch((e) => console.error(e));
  };
  const onTransferOwnership = (email: string) => () => {
    const isConfirmed = window.confirm(
      "You are about to transfer team's ownership. Are you sure?"
    );
    if (!isConfirmed) return;
    handleTransferOwnership({ email }).catch((e) => console.error(e));
  };

  const filteredTeamMembers: TTeamMember[] = useMemo(() => {
    return (teamMembersData?.teamMembers || []).filter(({ ...member }) =>
      Object.values(member).some((val) =>
        (val || "")
          .toString()
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [searchQuery, teamMembersData]);

  const filteredPendingTeamMembers: TPendingTeamMember[] = useMemo(() => {
    return (teamMembersData?.pendingTeamMembers || []).filter(({ ...member }) =>
      Object.values(member).some((val) =>
        (val || "")
          .toString()
          .toLocaleLowerCase()
          .includes(searchPendingQuery.toLocaleLowerCase())
      )
    );
  }, [searchPendingQuery, teamMembersData]);

  const handleChangeSearchQuery = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery]
  );

  const handleChangeSearchPendingQuery = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchPendingQuery(e.target.value);
    },
    [setSearchPendingQuery]
  );

  const handleCopyLink = useCallback(
    (link: string) => () => {
      navigator.clipboard.writeText(link || "").then(
        () => {
          alert("Link copied to the clipboard");
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    []
  );

  return (
    <UserManagementPresentation
      isLoadingMembers={isLoadingMembers}
      isLoadingUserAction={isLoadingUserAction}
      currentUserRole={currentUserRole}
      currentUserId={userData?.user?.id}
      list={filteredTeamMembers}
      pendingList={filteredPendingTeamMembers}
      onRemoveUser={onRemoveUser}
      onChangeRole={onChangeRole}
      onTransferOwnership={onTransferOwnership}
      onCopyLink={handleCopyLink}
      onChangeSearchQuery={handleChangeSearchQuery}
      onChangeSearchPendingQuery={handleChangeSearchPendingQuery}
    />
  );
};
