import styled, { keyframes } from "styled-components";
import { BiLoaderAlt as LoadingIcon } from "react-icons/bi";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const Icon = styled(LoadingIcon)`
  color: ${({ theme }) => theme.getColor("white")};
  animation: 1s linear infinite;
  animation-name: ${spin};
`;
