import { useCallback } from "react";

import { usePost } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TManageBillingBody, TResponse } from "./types";
import { useUser } from "../User";
import { useTeams } from "../Teams";

export const useRequestHandler = () => {
  const post = usePost<Omit<TManageBillingBody, "subscriptionId">, TResponse>();
  const { data: userData } = useUser();
  const { data: teams } = useTeams();

  return useCallback(
    async ({ subscriptionId, ...payload }: TManageBillingBody) => {
      return post({
        path: Endpoints.postManageBilling(
          userData?.user?.id || "",
          teams?.[0]?.id || "",
          subscriptionId
        ),
        body: {
          ...payload,
        },
        options: {
          apiVersion: 3,
        },
      });
    },
    [post]
  );
};
