import { PaymentErrorPresentation } from "./PaymentError.presentation";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const PaymentError = () => {
  const navigate = useNavigate();
  const handleDismiss = useCallback(() => {
    navigate(Routing.Root);
  }, [navigate]);

  return <PaymentErrorPresentation onDismiss={handleDismiss} />;
};
