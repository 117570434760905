import lottie from "lottie-web";
import { useEffect } from "react";
import * as S from "./Splash.styles";

import loading from "./assets/loading.json";

export type Props = {
  uniqueId: string;
  height?: string;
};

export function Splash(props: Props) {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      animationData: loading,
      autoplay: true,
      container: document.getElementById(props.uniqueId) as HTMLElement,
      loop: false,
      renderer: "svg",
    });

    const onComplete = () => {
      animation.setSpeed(0.5);
      animation.goToAndPlay(11, true);
    };

    animation.addEventListener("complete", onComplete);

    return () => {
      animation.removeEventListener("complete", onComplete);
      animation.destroy();
    };
  }, []);

  return (
    <S.Container>
      <S.Animation height={props.height || "100%"} id={props.uniqueId} />
    </S.Container>
  );
}
