import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import ThemeManager from "./contexts/ThemeManager";
import GlobalStyle, {
  GlobalFontStyles,
  HelmetFontPreload,
} from "./globalStyle";
import { ToastProvider } from "react-toast-notifications";
import {
  BROWSER_ENVIRONMENT,
  BROWSER_SENTRY_DSN_KEY,
  FACEBOOK_APP_ID,
} from "./constants/global";
import { AuthContextWrapper } from "./contexts/AuthContext";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = createRoot(document.getElementById("root") as HTMLElement);

console.log(
  `%cVersion #${import.meta.env.BROWSER_APP_VERSION}`,
  "color: #937ae6"
);

try {
  Sentry.init({
    dsn: BROWSER_SENTRY_DSN_KEY,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/5
      "Object Not Found Matching Id", // this comes from a microsoft crawler, we can ignore
    ],
    integrations: [Sentry.browserTracingIntegration()],
    release: `teams-app@${import.meta.env.BROWSER_APP_VERSION || ""}`,
    tracesSampleRate: BROWSER_ENVIRONMENT === "production" ? 0.1 : 1.0,
    normalizeDepth: 10,
    denyUrls: [
      /// https://docs.sentry.io/clients/javascript/tips/
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
} catch (e) {
  // nothing to do here if sentry is blocked, we will never know!
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeManager>
        <Helmet link={HelmetFontPreload}>
          <style>{GlobalFontStyles}</style>
          <script
            nonce="BT4ZbEUB"
            src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=${FACEBOOK_APP_ID}`}
          ></script>
        </Helmet>
        <GlobalStyle />
        <ToastProvider autoDismiss autoDismissTimeout={5000}>
          <AuthContextWrapper>
            <App />
          </AuthContextWrapper>
        </ToastProvider>
      </ThemeManager>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
