import { ReactNode, useEffect, useRef } from "react";

interface OutsideClickDetectorProps {
  /** Callback when click is detected outside the child component */
  onOutsideClick: () => void;
  eventName?: "mousedown" | "click";
  children: ReactNode | undefined;
}

/**
 * Utility component to detect if the mouseclick happened outside the component
 */
const OutsideClickDetector = ({
  children,
  eventName = "mousedown",
  onOutsideClick,
}: OutsideClickDetectorProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }

    document.addEventListener(eventName, handleClickOutside);

    return () => document.removeEventListener(eventName, handleClickOutside);
  }, [ref]);

  return <div ref={ref}>{children}</div>;
};

export default OutsideClickDetector;

export function useOutsideClickRef(params: { onOutsideClick: () => void }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        params.onOutsideClick();
      }
    }

    document.addEventListener("click", handleClickOutside, true);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [ref]);

  return ref;
}
