const bs = [0, 480, 768, 1024];

export const breakpoints = bs.map((b) => `${b}px`).slice(1, bs.length);

export const mediaQuery = {
  minWidth: {
    xs: `@media (min-width: ${breakpoints[0]})`,
    sm: `@media (min-width: ${breakpoints[1]})`,
    md: `@media (min-width: ${breakpoints[2]})`,
    lg: `@media (min-width: ${breakpoints[3]})`,
  },
  maxWidth: {
    xs: `@media (max-width: ${breakpoints[1]})`,
    sm: `@media (max-width: ${breakpoints[2]})`,
    md: `@media (max-width: ${breakpoints[3]})`,
    lg: `@media (max-width: ${breakpoints[4]})`,
  },
};
