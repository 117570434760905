import { TeamSubscriptionsPresentation } from "./TeamSubscriptions.presentation";
import { useSubscriptions } from "../../../../api/modules/Subscriptions";
import { useCheckoutSession } from "../../../../api/modules/CheckoutSession";
import { useCallback } from "react";
import {
  SubscriptionPaymentIntervals,
  TResponse,
} from "../../../../api/modules/CheckoutSession/types";
import { getCheckoutUrls, getManageBillingUrls } from "./lib/getCheckoutUrls";
import { isSuccessResponse } from "../../../../types/common/Api";
import { useManageBilling } from "../../../../api/modules/ManageBilling";
import { useTeamMembers } from "../../../../api/modules/TeamMembers";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { Permissions } from "../../../../types/common/Permissions";

export const TeamSubscriptions = () => {
  const { data: subscriptions, isLoading, isFetched } = useSubscriptions();
  const { data: teamMembersData } = useTeamMembers();
  const {
    mutateAsync: handleManageBillingRequest,
    isLoading: isLoadingManageBillingRequest,
  } = useManageBilling();
  const {
    mutateAsync: handleCheckoutRequest,
    isLoading: isLoadingCheckoutSession,
  } = useCheckoutSession();
  const getHasPermission = useHasPermission();
  const hasPermissionManagePayments = getHasPermission(
    Permissions.ManagePayments
  );

  const handleCheckout = useCallback(
    (subscriptionId: string, period: SubscriptionPaymentIntervals) => () => {
      handleCheckoutRequest({
        subscriptionId,
        period,
        ...getCheckoutUrls(),
      })
        .then((response: TResponse) => {
          if (isSuccessResponse(response)) {
            window.location.assign(response.result);
          }
        })
        .catch((err) => console.error(err));
    },
    [handleCheckoutRequest]
  );

  const handleManageBilling = useCallback(
    (subscriptionId: string) => () => {
      handleManageBillingRequest({
        subscriptionId,
        ...getManageBillingUrls(),
      })
        .then((response: TResponse) => {
          if (isSuccessResponse(response)) {
            window.location.assign(response.result);
          }
        })
        .catch((err) => console.error(err));
    },
    [handleManageBillingRequest]
  );

  return (
    <TeamSubscriptionsPresentation
      isFetched={isFetched}
      hasPermissionManagePayments={hasPermissionManagePayments}
      subscriptions={subscriptions}
      isLoading={
        isLoading || isLoadingCheckoutSession || isLoadingManageBillingRequest
      }
      onCheckout={handleCheckout}
      onManageBilling={handleManageBilling}
      teamMembersAmount={(teamMembersData?.teamMembers || []).length}
    />
  );
};
