import * as S from "./Flex.styles";
import { Props } from "./Flex.types";

export const Flex = (props: Props) => {
  const { children, ...otherProps } = props;

  return (
    <S.Flex display="flex" {...otherProps}>
      {children}
    </S.Flex>
  );
};
