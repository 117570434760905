import React from "react";
import { ColorsWithLevels } from "../../../constants/palette/types";

export enum TextVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  body1 = "body1",
  body2 = "body2",
  body3 = "body3",
  body4 = "body4",
  caption = "caption",
}

export type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: keyof ColorsWithLevels;
  dangerouslySetInnerHTML?: { __html: string };
  id?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textTransform?: string;
  fontSize?: string;
  fontFamily?: string;
  fontStyle?: "normal" | "italic";
  fontWeight?: "inherit" | 300 | 400 | 500 | 600 | 700;
  shouldTruncate?: boolean;
  textAlign?: "left" | "center" | "right";
  title?: string;
  variant?: TextVariant;
};
