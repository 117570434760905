import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Routing } from "../../../constants/routing";
import { getHostedDomain } from "../../../utils/getHostedDomain";
import { useAuthContext } from "../../../contexts/AuthContext";

export const useLogoutUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setToken } = useAuthContext();

  return useCallback(() => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    const domain = getHostedDomain();
    setToken(null);
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain}`;
    queryClient.clear();
    navigate(Routing.Root);
  }, [navigate, queryClient, setToken]);
};
