import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { CreateSubscriptionFormType } from "./CreateSubscriptionForm.types";
import { isSuccessResponse } from "../../../../types/common/Api";
import { CreateSubscriptionFormPresentation } from "./CreateSubscriptionForm.presentation";
import { useCreateSubscription } from "../../../../api/modules/CreateSubscription";

export const CreateSubscriptionForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm<CreateSubscriptionFormType>({
    mode: "onChange",
  });
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const { mutateAsync, isLoading } = useCreateSubscription();
  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        numberOfSeats: data.numberOfSeats,
        isRenewing: true,
      },
      {
        onSuccess: (res) => {
          if (!isSuccessResponse(res)) {
            setFormErrorMessage(
              res.messages?.[0] || "Error creating a subscription"
            );
            return;
          }
        },
      }
    );
  });
  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  return (
    <CreateSubscriptionFormPresentation
      touchedFields={touchedFields}
      errors={errors}
      register={register}
      isValid={isValid}
      isLoading={isLoading}
      formErrorMessage={formErrorMessage}
      onChangeAnyField={handleChangeAnyField}
      onSubmit={onSubmit}
    />
  );
};
