import { names } from "./names";
import { breakpoints, mediaQuery } from "./breakpoints";
import { space } from "./space";
import { fonts } from "./fontFamily";
import { getColor, colors } from "./colors";
import { animations } from "./animations";

export default {
  animations,
  names,
  space,
  breakpoints,
  mediaQuery,
  fonts,
  getColor,
  colors,
};
