import { useCallback } from "react";

import { useDelete } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TDeleteUserBody, TResponse } from "./types";
import { useUser } from "../User";
import { useTeams } from "../Teams";

export const useRequestHandler = () => {
  const del = useDelete<TDeleteUserBody, TResponse>();
  const { data: userData } = useUser();
  const { data: teams } = useTeams();

  return useCallback(
    async (payload: TDeleteUserBody) => {
      return del({
        path: Endpoints.deleteUser(
          userData?.user?.id || "",
          teams?.[0]?.id || ""
        ),
        body: {
          ...payload,
        },
        options: {
          apiVersion: 3,
        },
      });
    },
    [del]
  );
};
