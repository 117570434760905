import Form from "../../../../components/FormComponents/Form";
import Input from "../../../../components/FormComponents/Input";
import Flex from "../../../../components/LayoutComponents/Flex";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../../../components/FormComponents/Button";
import { PresentationProps } from "./AddUserForm.types";
import { UserRoles } from "../../../../api/modules/TeamMembers/types";
import Radio from "../../../../components/FormComponents/Radio";
import Text from "../../../../components/TypographyComponents/Text";
import Box from "../../../../components/LayoutComponents/Box";

export const AddUserFormPresentation = (props: PresentationProps) => {
  const {
    onSubmit,
    touchedFields,
    errors,
    register,
    onChangeAnyField,
    isValid,
    isLoading,
    formErrorMessage,
  } = props;
  return (
    <Form flexDirection="column" flexGrow={1} width="100%" onSubmit={onSubmit}>
      <Box mb={2}>
        <Text.H5>Add by email</Text.H5>
      </Box>
      <Flex
        alignItems="flex-start"
        alignSelf="center"
        flexDirection="column"
        flexGrow={1}
        width="100%"
      >
        <Input
          id="email"
          label="email"
          isTouched={touchedFields.email}
          errorMessage={errors.email?.message}
          {...register("email", {
            onChange: onChangeAnyField,
            required: true,
          })}
        />
      </Flex>
      <Flex
        alignItems="flex-start"
        alignSelf="center"
        flexDirection="column"
        flexGrow={1}
        width="100%"
        mt={5}
      >
        <Radio
          defaultChecked={false}
          id="roleAdmin"
          label="Admin"
          value={UserRoles.Admin}
          {...register("role", { required: true })}
        />
      </Flex>
      <Flex
        alignItems="flex-start"
        alignSelf="center"
        flexDirection="column"
        flexGrow={1}
        width="100%"
        mt={2}
      >
        <Radio
          defaultChecked={true}
          id="roleMember"
          label="Member"
          value={UserRoles.Member}
          {...register("role", { required: true })}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="center" mt={7}>
        <Button
          disabled={!isValid}
          isFullWidth={true}
          type="submit"
          variant={ButtonVariants.Primary}
          variantState={
            isLoading
              ? ButtonVariantStates.Loading
              : formErrorMessage
              ? ButtonVariantStates.Error
              : ButtonVariantStates.Idle
          }
        >
          {formErrorMessage || "add user"}
        </Button>
      </Flex>
    </Form>
  );
};
