import { createContext, useContext, useMemo, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { DEFAULT_THEME } from "../../constants/global";
import { Props, ThemeManagerContext } from "./ThemeManager.types";
import themes from "../../constants/themes";
import { Theme } from "../../types/theme";

const ThemeContext = createContext<ThemeManagerContext>(() => {});

export const ThemeManager = ({ children }: Props) => {
  const [theme, setTheme] = useState<Theme>(DEFAULT_THEME);
  const t = useMemo<DefaultTheme>(() => themes[theme], [theme]);

  return (
    <ThemeContext.Provider value={setTheme}>
      <ThemeProvider theme={t}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useSetTheme = () => useContext(ThemeContext);
