import { useCallback } from "react";

import { Logger } from "../../utils/logger";
import {
  ClientParams,
  GetParams,
  PostParams,
  PutParams,
  UseGetParams,
  UsePostParams,
  UsePutParams,
  DeleteParams,
  UseDeleteParams,
} from "./types";
import { getBaseUrl } from "../common/lib/getBaseUrl";
import { useAuthContext } from "../../contexts/AuthContext";

export const DEFAULT_ERROR_MESSAGE =
  "There might be a server issue. Please try again in a little bit.";

export async function client<T>(params: ClientParams): Promise<T> {
  const { path, token, config, options } = params;

  try {
    const baseUrl = getBaseUrl(options?.apiVersion);
    const url = path.startsWith("http") ? path : `${baseUrl}${path}`;
    const headers: HeadersInit = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
    const request = new Request(url, {
      ...config,
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...config.headers,
      },
    });
    const response = await fetch(request);

    return await response.json().catch((err) => Logger.error(err));
  } catch (error) {
    Logger.error(error);
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }
}

export const get = async <T>(params: GetParams): Promise<T> => {
  const { path, token, config, options } = params;
  return client<T>({
    path,
    token,
    options,
    config: { method: "get", ...config },
  });
};

export const useGet = <T>(): ((params: UseGetParams) => Promise<T>) => {
  const { token } = useAuthContext();

  return useCallback<(params: UseGetParams) => Promise<T>>(
    (params: UseGetParams) => {
      return get<T>({ token, ...params });
    },
    [token]
  );
};

export const post = async <T, U>(params: PostParams<T>): Promise<U> => {
  const { path, token, body, options, config } = params;

  return client<U>({
    path,
    token,
    options,
    config: { method: "post", body: JSON.stringify(body), ...config },
  });
};

export const usePost = <T, U>(): ((params: UsePostParams<T>) => Promise<U>) => {
  const { token } = useAuthContext();

  return useCallback<(params: UsePostParams<T>) => Promise<U>>(
    (params) => {
      return post<T, U>({ token, ...params });
    },
    [token]
  );
};

export const put = async <T, U>(params: PutParams<T>): Promise<U> => {
  const { path, token, body, config, options } = params;
  return client<U>({
    path,
    token,
    options,
    config: { method: "put", body: JSON.stringify(body), ...config },
  });
};

export const usePut = <T, U>(): ((params: UsePutParams<T>) => Promise<U>) => {
  const { token } = useAuthContext();

  return useCallback<(params: UsePutParams<T>) => Promise<U>>(
    (params) => {
      return put<T, U>({ token, ...params });
    },
    [token]
  );
};

export const deleteCall = async <T, U>(params: DeleteParams<T>): Promise<U> => {
  const { path, token, body, config, options } = params;
  return client<U>({
    path,
    token,
    options,
    config: { method: "delete", body: JSON.stringify(body), ...config },
  });
};

export const useDelete = <T, U>(): ((
  params: UseDeleteParams<T>
) => Promise<U>) => {
  const { token } = useAuthContext();

  return useCallback<(params: UseDeleteParams<T>) => Promise<U>>(
    (params) => {
      return deleteCall<T, U>({ token, ...params });
    },
    [token]
  );
};
