import { PresentationProps } from "./PaymentError.types";
import Panel from "../../components/Panel";
import Text from "../../components/TypographyComponents/Text";
import * as S from "./PaymentError.styles";
import { Button, ButtonVariants } from "../../components/FormComponents/Button";
import Flex from "../../components/LayoutComponents/Flex";

export const PaymentErrorPresentation = (props: PresentationProps) => {
  const { onDismiss } = props;
  return (
    <S.Wrapper
      justifyContent="center"
      minHeight="100vh"
      alignItems="center"
      flexDirection="column"
    >
      <Panel alignItems="center" flexDirection="column" mb={5} maxWidth="500px">
        <Text.H2>Something went wrong</Text.H2>
        <Flex my={6} textAlign="center">
          <Text.Body2>
            Your payment failed. You can try again anytime later from your team
            page
          </Text.Body2>
        </Flex>
        <Button variant={ButtonVariants.Primary} onClick={onDismiss}>
          Go to my team page
        </Button>
      </Panel>
    </S.Wrapper>
  );
};
