import { TransitionStatus } from "react-transition-group";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div<{ animationState: TransitionStatus }>`
  position: absolute;
  z-index: 999;
  right: 0;
  padding: 1rem 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case "entering":
        return `translateY(-0.5rem)`;
      case "entered":
        return `translateY(0)`;
      case "exiting":
        return `translateY(0)`;
      case "exited":
        return `translateY(-0.5rem)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case "entering":
        return 0;
      case "entered":
        return 1;
      case "exiting":
        return 0.5;
      case "exited":
        return 0;
    }
  }};
`;

export const MenuItemContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #565487;
  background: #191736;
  border-radius: 0.75rem;
  padding: 0.5rem;
`;

export const MenuItem = styled.a<{ disabled?: boolean }>`
  text-decoration: none;
  font-size: 0.825rem;
  font-style: normal;
  font-weight: 500;
  color: #d4d2ea;
  padding: 0.5rem 1rem;
  margin: 0.125rem 0;
  cursor: pointer;
  border-radius: 0.75rem;
  white-space: nowrap;
  transition: background 0.15s ease-in-out;
  ${({ disabled }) => (disabled ? `opacity: .5;` : "")}

  &:hover {
    background: #333152;
    backdrop-filter: blur(34px);
  }
`;
