import { useCallback } from "react";

import { useDelete } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TResponse } from "./types";
import { useUser } from "../User";

export const useRequestHandler = () => {
  const del = useDelete<{}, TResponse>();
  const { data: userData } = useUser();

  return useCallback(
    async (teamId: string) => {
      return del({
        path: Endpoints.deleteTeam(userData?.user?.id || "", teamId),
        body: {},
        options: {
          apiVersion: 3,
        },
      });
    },
    [del]
  );
};
