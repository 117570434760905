import { ReactNode, useState } from "react";

import { DropdownMenuDisplay, MenuItemType } from "./DropdownMenu.display";

interface Props {
  onOpen?: () => void;
  children: ReactNode;
  menuItems: MenuItemType[];
}

export const DropdownMenu = ({
  children,
  menuItems,
  onOpen = () => {},
}: Props) => {
  const [isActive, setActive] = useState(false);

  const handleProfileClick = () => {
    setActive(!isActive);
    onOpen();
  };

  return (
    <DropdownMenuDisplay
      isActive={isActive}
      menuItems={menuItems}
      onClick={handleProfileClick}
      onOutsideClick={() => setActive(false)}
    >
      {children}
    </DropdownMenuDisplay>
  );
};
