export const Endpoints = {
  getUser: "/users/me",
  getTeams: (userId: string) => `/users/${userId}/teams`,
  getTeamMembers: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/members`,
  getSubscriptions: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/subscriptions`,
  postAuthorizeUser: "/auth/email-login",
  postGoogleSignIn: "/auth/google-login",
  postAppleSignIn: "/auth/apple-login",
  postFacebookSignIn: "/auth/facebook-login",
  postCreateTeam: (userId: string) => `/users/${userId}/teams`,
  putUpdateTeam: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}`,
  deleteTeam: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}`,
  deleteUser: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/remove`,
  postUpdateUserRole: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/changeRole`,
  postTransferOwnership: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/transferOwnership`,
  postAddUser: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/add`,
  postBulkAddUsers: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/bulkAdd`,
  postAddDomain: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/domains`,
  deleteDomain: (userId: string, teamId: string, domainId: string) =>
    `/users/${userId}/teams/${teamId}/domains/${domainId}`,
  postCheckoutSession: (
    userId: string,
    teamId: string,
    subscriptionId: string
  ) =>
    `/users/${userId}/teams/${teamId}/subscription/${subscriptionId}/createCheckoutSession`,
  postManageBilling: (userId: string, teamId: string, subscriptionId: string) =>
    `/users/${userId}/teams/${teamId}/subscription/${subscriptionId}/createBillingManagementSession`,
  postCreateSubscription: (userId: string, teamId: string) =>
    `/users/${userId}/teams/${teamId}/subscription`,
  resendVerificationEmail: (userId: string) =>
    `/users/${userId}/sendVerificationEmail`,
};
