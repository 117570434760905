import { useQuery } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { Queries } from "../../common/queries";
import { TUser } from "./types";
import { useRequestHandler } from "./useRequestHandler";

export const useUser = () => {
  const { addToast } = useToasts();
  const handleGetUser = useRequestHandler();

  return useQuery<TUser | null>(Queries.User, handleGetUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      addToast("Error getting user data", {
        appearance: "error",
      });
    },
  });
};
