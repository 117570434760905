import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TAuthorizeUserBody, TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { useAuthContext } from "../../../contexts/AuthContext";
import { isSuccessResponse } from "../../../types/common/Api";

export const useAuthorizeUser = () => {
  const { addToast } = useToasts();
  const handleSignIn = useRequestHandler();
  const { setToken } = useAuthContext();
  return useMutation<TResponse, unknown, TAuthorizeUserBody>(
    Mutations.Login,
    (payload) => handleSignIn(payload),
    {
      onError: () => {
        addToast("Authorization Error", {
          appearance: "error",
        });
      },
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          setToken(response.result);
        }
      },
    }
  );
};
