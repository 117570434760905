import React, { forwardRef, Ref } from "react";

import * as S from "./Textarea.styles";
import { Props } from "./Textarea.types";
import Flex from "../../LayoutComponents/Flex";
import ValidationMessage from "../../ValidationMessage";

const TextareaComponent = (props: Props, ref?: Ref<HTMLTextAreaElement>) => {
  const {
    id,
    placeholder,
    name,
    value,
    rows,
    isTouched,
    isDisabled,
    errorMessage,
    onBlur,
    onChange,
  } = props;

  return (
    <Flex flexDirection="column" position="relative" width="100%">
      <S.Textarea
        ref={ref}
        hasError={Boolean(errorMessage && isTouched)}
        id={id}
        isDisabled={isDisabled}
        name={name}
        placeholder={placeholder}
        value={value}
        rows={rows}
        onBlur={onBlur}
        onChange={onChange}
      />
      {isTouched && errorMessage && (
        <ValidationMessage errorMessage={errorMessage} />
      )}
    </Flex>
  );
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  TextareaComponent
);
