import React, { forwardRef, Ref } from "react";

import {
  IoIosCloseCircle as ErrorIcon,
  IoIosCheckmarkCircle as SuccessIcon,
} from "react-icons/io";
import { useTheme } from "styled-components";
import * as S from "./Input.styles";
import { Props } from "./Input.types";
import Flex from "../../LayoutComponents/Flex";
import LoadingIcon from "../../LoadingComponents/LoadingIcon";
import ValidationMessage from "../../ValidationMessage";
import Label from "../Label";

const InputComponent = (props: Props, ref?: Ref<HTMLInputElement>) => {
  const {
    id,
    type,
    label,
    name,
    value,
    isTouched,
    isDisabled,
    errorMessage,
    isLoading,
    isSuccessful,
    hasErrorIcon,
    borderRadius,
    padding,
    fontSize,
    placeholder,
    onChange,
    onBlur,
    onClick,
    ...otherProps
  } = props;
  const theme = useTheme();
  return (
    <Flex
      flexDirection="column"
      position="relative"
      width="100%"
      onClick={onClick}
    >
      {label && id ? <Label htmlFor={id}>{label}</Label> : null}
      <Flex flexDirection="column" position="relative" width="100%">
        <S.Input
          ref={ref}
          borderRadius={borderRadius}
          fontSize={fontSize}
          hasError={Boolean(errorMessage && isTouched)}
          hasValue={Boolean(value)}
          id={id}
          isDisabled={isDisabled}
          name={name}
          padding={padding}
          placeholder={placeholder}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...otherProps}
        />
        <S.IconWrapper>
          {hasErrorIcon && (
            <ErrorIcon color={theme.colors.negative} size={18} />
          )}
          {isLoading && <LoadingIcon />}
          {isSuccessful && (
            <SuccessIcon color={theme.colors.positive} size={18} />
          )}
        </S.IconWrapper>
      </Flex>

      {isTouched && errorMessage && (
        <ValidationMessage errorMessage={errorMessage} />
      )}
    </Flex>
  );
};

export const Input = forwardRef<HTMLInputElement, Props>(InputComponent);
