import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TResponse, TCheckoutSessionBody } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";

export const useCheckoutSession = () => {
  const { addToast } = useToasts();
  const handleCheckoutSession = useRequestHandler();

  return useMutation<TResponse, unknown, TCheckoutSessionBody>(
    Mutations.CheckoutSession,
    (payload) => handleCheckoutSession(payload),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          return;
        }
        addToast("Error creating checkout session", {
          appearance: "error",
        });
      },
    }
  );
};
