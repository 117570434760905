import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { saveTokenInCookie } from "../../utils/setTokenInCookie";

type Props = {
  children: ReactNode;
};

export type AuthContextType = {
  token: string | null;
  setToken: (t: string | null) => void;
  isAuthDataReady: boolean;
  isAuthorized: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  token: null,
  setToken: () => {},
  isAuthDataReady: false,
  isAuthorized: false,
});

export const AuthContextWrapper = (props: Props) => {
  const { children } = props;
  const [token, setToken] = useState<null | string>(null);
  const [isAuthDataReady, setIsAuthDataReady] = useState(false);
  const handleSetToken = useCallback(
    (t: string | null) => {
      setToken(t);
      saveTokenInCookie(t);
    },
    [setToken, saveTokenInCookie]
  );
  useEffect(() => {
    const onPageLoad = () => {
      const cookies = document.cookie.split(";");

      const tokenFromCookie = cookies.find((elem) =>
        elem.trim().startsWith("token=")
      );
      if (tokenFromCookie) {
        const splitToken = tokenFromCookie.split("=");
        if (splitToken[1]) {
          setToken(splitToken?.[1] || null);
        }
      }

      setIsAuthDataReady(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => document.removeEventListener("load", onPageLoad);
    }
  }, []);
  const isAuthorized = Boolean(token) && isAuthDataReady;

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken: handleSetToken,
        isAuthDataReady,
        isAuthorized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};
