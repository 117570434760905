import styled from "styled-components";
import Flex from "../../components/LayoutComponents/Flex";

export const HR = styled.hr`
  color: rgba(55, 62, 91, 0.7);
  width: 100%;
`;
export const Wrapper = styled(Flex)`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in;
`;
