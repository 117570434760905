import { useMemo } from "react";
import { TDetailedSubscription } from "../api/modules/Subscriptions/types";
import { useHasPermission } from "./useHasPermission";
import { Permissions } from "../types/common/Permissions";

type Props = {
  subscriptions?: TDetailedSubscription[];
};
export const useCanRemoveTeam = ({ subscriptions }: Props) => {
  const getHasPermission = useHasPermission();
  return useMemo(() => {
    const hasActiveSubscription = (subscriptions || []).some(
      (sub) => sub.hasPlatformAccess
    );
    const hasPermission = getHasPermission(Permissions.RemoveTeam);
    return hasPermission && !hasActiveSubscription;
  }, [subscriptions, getHasPermission]);
};
