import { useEffect } from "react";
import { APPLE_CLIENT_ID } from "../constants/global";

export const useInitializeAppleAuth = () => {
  useEffect(() => {
    if (typeof AppleID === "undefined") return;

    AppleID.auth.init({
      clientId: APPLE_CLIENT_ID,
      scope: "email name",
      redirectURI: window.location.origin,
      usePopup: true,
    });
  }, [typeof AppleID]);
};
