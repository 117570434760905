import type { CredentialResponse } from "google-one-tap";
import { useEffect } from "react";
import { GOOGLE_CLIENT_ID } from "../constants/global";

type Props = {
  callback: (credentialResponse: CredentialResponse) => void;
  context?: "signin" | "signup" | "use";
};
export const useInitializeGoogleAuth = (props: Props) => {
  const { callback, context } = props;

  useEffect(() => {
    if (typeof window.google === "undefined") return;
    const googleBtn = document.getElementById("google-button");
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback,
      context: context || "signin",
    });

    if (googleBtn) {
      window.google.accounts.id.renderButton(googleBtn, {
        logo_alignment: "left",
        theme: "filled_black",
        shape: "pill",
        size: "large",
      });
    }
    window.google.accounts.id.prompt();
  }, [typeof window.google]);
};
