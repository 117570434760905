import { PresentationProps } from "./MyTeam.types";
import * as S from "./MyTeam.styles";
import Text from "../../components/TypographyComponents/Text";
import Flex from "../../components/LayoutComponents/Flex";
import Panel from "../../components/Panel";
import { CreateTeamForm } from "./components/CreateTeamForm";
import { TeamData } from "./components/TeamData";
export const MyTeamPresentation = (props: PresentationProps) => {
  const { hasTeam } = props;

  return (
    <S.Wrapper flexDirection="column" width="100%">
      <Text.H1>My Team</Text.H1>
      {!hasTeam ? (
        <>
          <Flex mt={3}>
            <Text.Body1>You do not have a team.</Text.Body1>
          </Flex>
          <Flex mt={7} flexDirection="column" width="100%">
            <Text.H1>Create a team</Text.H1>
            <Panel mt={3}>
              <CreateTeamForm />
            </Panel>
          </Flex>
        </>
      ) : (
        <TeamData />
      )}
    </S.Wrapper>
  );
};
