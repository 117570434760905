import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TResponse, TDeleteUserBody } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";
import { Queries } from "../../common/queries";

export const useDeleteUser = () => {
  const { addToast } = useToasts();
  const handleDeleteUser = useRequestHandler();
  const queryClient = useQueryClient();

  return useMutation<TResponse, unknown, TDeleteUserBody>(
    Mutations.DeleteUser,
    (payload) => handleDeleteUser(payload),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient
            .invalidateQueries(Queries.TeamMembers)
            .catch((e) => console.error(e));
          return;
        }
        addToast("Error deleting a team member", {
          appearance: "error",
        });
      },
    }
  );
};
