import { ButtonHTMLAttributes } from "react";

import * as S from "./Button.styles";

export enum Variants {
  Base = "Base",
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
  Outline = "Outline",
}

export enum VariantStates {
  Error = "Error",
  Idle = "Idle",
  Loading = "Loading",
  Success = "Success",
}

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  analyticsEvent?: string;
  backgroundColor?: string;
  "data-testid"?: string;
  isFullWidth?: boolean;
  variant?: Variants;
  variantState?: VariantStates;
}

export const Button = ({
  analyticsEvent,
  backgroundColor,
  children,
  "data-testid": testId,
  disabled = false,
  isFullWidth = false,
  onClick = () => {},
  variant = Variants.Base,
  variantState = VariantStates.Idle,
  ...rest
}: Props) => {
  const props = {
    "data-analytics-event": analyticsEvent,
    "data-testid": testId,
    backgroundColor,
    children,
    disabled:
      disabled ||
      [
        VariantStates.Error,
        VariantStates.Success,
        VariantStates.Loading,
      ].includes(variantState),
    isFullWidth,
    onClick,
    variantState,
    ...rest,
  };

  switch (variant) {
    case Variants.Base:
      return <S.BaseButton {...props} />;
    case Variants.Primary:
      return <S.PrimaryButton {...props} />;
    case Variants.Secondary:
      return <S.SecondaryButton {...props} />;
    case Variants.Tertiary:
      return <S.TertiaryButton {...props} />;
    case Variants.Outline:
      return <S.OutlineButton {...props} />;
  }
};
