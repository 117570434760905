import Flex from "../LayoutComponents/Flex";
import Text from "../TypographyComponents/Text";
import { WEB_APP_URL } from "../../constants/global";
import { FiExternalLink as ExternalLinkIcon } from "react-icons/fi";

export const Footer = () => {
  return (
    <Flex
      justifyContent="space-between"
      p={3}
      mt={5}
      width="100%"
      borderTop="1px solid rgba(55, 62, 91, 0.7)"
    >
      <Flex>
        <Text.Body4>
          © {new Date().getFullYear()} BRAINFM, INC.
          <br />
          MADE WITH LOVE IN NYC
        </Text.Body4>
      </Flex>
      <Flex alignItems="center">
        <a href={WEB_APP_URL} target="_blank" rel="noreferrer">
          <Flex gap="5px" alignItems="center">
            <ExternalLinkIcon color="white" />
            <Text.Body3>brain.fm web</Text.Body3>
          </Flex>
        </a>
      </Flex>
    </Flex>
  );
};
