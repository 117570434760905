import { SELF_URL } from "../constants/global";

export const getDomainFromURL = (URL: string) => {
  const [topLevelDomain, domain] = URL.split(".").reverse();
  return `${domain}.${topLevelDomain}`;
};

export const getHostedDomain = (): string => {
  if (import.meta.env.BROWSER_APP_LOCAL === "1") {
    return getDomainFromURL(".localhost");
  }

  return getDomainFromURL(SELF_URL || "brain.fm");
};
