import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { CreateTeamFormType } from "./CreateTeamForm.types";
import { isSuccessResponse } from "../../../../types/common/Api";
import { useCreateTeam } from "../../../../api/modules/CreateTeam";
import { CreateTeamFormPresentation } from "./CreateTeamForm.presentation";

export const CreateTeamForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm<CreateTeamFormType>({
    mode: "onChange",
  });
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const { mutateAsync, isLoading } = useCreateTeam();
  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        title: data.title,
      },
      {
        onSuccess: (res) => {
          if (!isSuccessResponse(res)) {
            setFormErrorMessage(res.messages?.[0] || "Error creating a team");
            return;
          }
        },
      }
    );
  });
  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  return (
    <CreateTeamFormPresentation
      touchedFields={touchedFields}
      errors={errors}
      register={register}
      isValid={isValid}
      isLoading={isLoading}
      formErrorMessage={formErrorMessage}
      onChangeAnyField={handleChangeAnyField}
      onSubmit={onSubmit}
    />
  );
};
