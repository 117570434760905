import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TCreateTeamBody, TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";
import { Queries } from "../../common/queries";

export const useCreateTeam = () => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const handleCreateTeam = useRequestHandler();

  return useMutation<TResponse, unknown, TCreateTeamBody>(
    Mutations.CreateTeam,
    (payload) => handleCreateTeam(payload),
    {
      onError: () => {
        addToast("Error creating a team", {
          appearance: "error",
        });
      },
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient
            .invalidateQueries(Queries.Teams)
            .catch((e) => console.error(e));
        }
      },
    }
  );
};
