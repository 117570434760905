import { VerifyEmailPresentation } from "./VerifyEmail.presentation";
import { useCallback } from "react";
import { useResendVerificationEmail } from "../../api/modules/ResendVerificationEmail";
import { VERIFICATION_URL } from "../../constants/global";
import { useUser } from "../../api/modules/User";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const VerifyEmail = () => {
  const { data: userData } = useUser();
  const navigate = useNavigate();
  const callback = () => {
    location.assign(VERIFICATION_URL);
  };
  const { mutateAsync: handleSendEmailRequest, isLoading } =
    useResendVerificationEmail({
      callback,
    });
  const handleSendEmail = useCallback(async () => {
    await handleSendEmailRequest();
  }, [handleSendEmailRequest]);

  if (userData?.verification?.isVerified) {
    navigate(Routing.Root);
  }

  return (
    <VerifyEmailPresentation
      isLoading={isLoading}
      onSendEmail={handleSendEmail}
    />
  );
};
