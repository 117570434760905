import { createGlobalStyle } from "styled-components";
import { HelmetProps } from "react-helmet";

export const GlobalFontStyles = `
  @font-face {
    font-family: TTNormsPro-Regular;
    src: url('/fonts/TTNormsPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: TTNormsPro-Medium;
    src: url('/fonts/TTNormsPro-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: TTNormsPro-Bold;
    src: url('/fonts/TTNormsPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`;

export const HelmetFontPreload: HelmetProps["link"] = [
  {
    rel: "preload",
    href: "./fonts/TTNormsPro-Regular.ttf",
    as: "font",
    type: "font/ttf",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "./fonts/TTNormsPro-Medium.ttf",
    as: "font",
    type: "font/ttf",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "./fonts/TTNormsPro-Bold.ttf",
    as: "font",
    type: "font/ttf",
    crossOrigin: "anonymous",
  },
];

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.family.ttnorms};
    background-image: url(https://cdn.brain.fm/images/bowtie.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(25, 23, 54);
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgba(10, 19, 51, 0.5);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
    z-index: 10;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-30px);
  }

  @keyframes modalEnter {
    from {
      // transform: translate(-50%, 0%);
      opacity: 0;
    }
    to {
      // transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeInAndUp {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes rotateFull {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: white;
      background: transparent;
    }
  }
`;
