import styled, { css } from "styled-components";

import { HTMLAttributes } from "react";
import { Props } from "./Text.types";
import createShouldForwardProp from "../../../utils/createShouldForwardProp";

const overrideDefaultStyles = css<Props>`
  ${({ color, theme: { colors, getColor } }) =>
    color ? `color: ${colors[color]};` : `color: ${getColor("typography", 0)};`}
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : "")}
  ${({ fontStyle }) => (fontStyle ? `font-style: ${fontStyle};` : "")}
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : "")}
  ${({ fontFamily }) => (fontFamily ? `font-family: ${fontFamily || ""};` : "")}
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : "")}
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : "")}
  ${({ letterSpacing }) =>
    letterSpacing ? `letter-spacing: ${letterSpacing || ""};` : ""}
  ${({ textTransform }) =>
    textTransform ? `text-transform: ${textTransform || ""};` : ""}
`;

export const truncateStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const BaseText = styled.p.withConfig({
  shouldForwardProp: createShouldForwardProp<
    HTMLAttributes<HTMLParagraphElement>
  >(
    "color",
    "fontSize",
    "fontStyle",
    "fontWeight",
    "shouldTruncate",
    "textAlign",
    "lineHeight"
  ),
})<Props>`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.family.default.medium};
  ${overrideDefaultStyles};
  ${(props) => (props.shouldTruncate ? truncateStyles : "")};
`;

export const H1 = styled(BaseText).attrs({ as: "h1" })`
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  font-family: ${({ theme }) => theme.fonts.family.default.bold};
  ${overrideDefaultStyles};
`;

export const H2 = styled(BaseText).attrs({ as: "h2" })`
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  font-family: ${({ theme }) => theme.fonts.family.default.bold};
  ${overrideDefaultStyles};
`;

export const H3 = styled(BaseText).attrs({ as: "h3" })`
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  font-family: ${({ theme }) => theme.fonts.family.default.bold};
  ${overrideDefaultStyles};
`;

export const H4 = styled(BaseText).attrs({ as: "h4" })`
  font-size: 19px;
  font-weight: 600;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fonts.family.default.bold};
  ${overrideDefaultStyles};
`;

export const H5 = styled(BaseText).attrs({ as: "h5" })`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.family.default.bold};
  ${overrideDefaultStyles};
`;

export const H6 = styled(BaseText).attrs({ as: "h6" })`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  ${overrideDefaultStyles};
`;

export const Body1 = styled(BaseText).attrs({ as: "p" })`
  font-size: 18px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.family.default.regular};
  ${overrideDefaultStyles};
`;

export const Body2 = styled(BaseText).attrs({ as: "p" })`
  font-size: 16px;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fonts.family.default.regular};
  ${overrideDefaultStyles};
`;

export const Body3 = styled(BaseText).attrs({ as: "p" })`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.family.default.regular};
  ${overrideDefaultStyles};
`;

export const Body4 = styled(BaseText).attrs({ as: "p" })`
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.family.default.regular};
  ${overrideDefaultStyles};
`;

export const Caption = styled(BaseText).attrs({ as: "span" })`
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.fonts.family.default.regular};
  ${overrideDefaultStyles};
`;
