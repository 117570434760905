import { Theme } from "../types/theme";

export const DEFAULT_THEME = Theme.MAIN;

export const API_BASE_URL = import.meta.env.BROWSER_API_BASE_URL as string;
// export const API_BASE_URL = "http://localhost:8080/v2" as string;
export const API_V3_BASE_URL = import.meta.env
  .BROWSER_API_V3_BASE_URL as string;
// export const API_V3_BASE_URL = "http://localhost:8080/v3" as string;
export const GOOGLE_CLIENT_ID = import.meta.env
  .BROWSER_GOOGLE_CLIENT_ID as string;
export const APPLE_CLIENT_ID = import.meta.env
  .BROWSER_APPLE_CLIENT_ID as string;
export const FACEBOOK_APP_ID = import.meta.env
  .BROWSER_FACEBOOK_APP_ID as string;
export const SELF_URL = import.meta.env.BROWSER_SELF_URL as string;
export const BROWSER_ENVIRONMENT = import.meta.env
  .BROWSER_ENVIRONMENT as string;
export const WEB_APP_URL = import.meta.env.BROWSER_WEB_APP_URL as string;
export const BROWSER_SENTRY_DSN_KEY = import.meta.env
  .BROWSER_SENTRY_DSN_KEY as string;

export const CUSTOMER_SUPPORT_EMAIL =
  "mailto:support@brain.fm?subject=Support%20request%20for%20teams.brain.fm";

export const VERIFICATION_URL = `${WEB_APP_URL}/user/account`; // can be replaced later with a deep link once verification form is implemented on mobile platform as well
