import styled from "styled-components";
import Flex from "../../../LayoutComponents/Flex";

export const LinkWrapper = styled(Flex)<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled ? `pointer-events: none; opacity: 0.5;` : ""}
  a {
    opacity: 0.7;
    transition: 0.3s ease all;
    color: ${({ theme }) => theme.getColor("white")};
    &:hover {
      opacity: 1;
    }
  }
`;
