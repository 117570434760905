import { TGenericResponse } from "../../../types/common/Api";

export type TResponse = TGenericResponse<{
  result: string;
}>;

export enum SubscriptionPaymentIntervals {
  Monthly = "monthly",
  Yearly = "yearly",
}

export type TCheckoutSessionBody = {
  period: SubscriptionPaymentIntervals;
  subscriptionId: string;
  successUrl: string;
  cancelUrl: string;
};
