import styled, { css } from "styled-components";
import { LabelHTMLAttributes } from "react";

import createShouldForwardProp from "../../../utils/createShouldForwardProp";
import Flex from "../../LayoutComponents/Flex";

const disabledStyles = css`
  label {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const checkedStyles = css<{ isSuccessful?: boolean }>`
  background-color: ${({ theme, isSuccessful }) =>
    theme.getColor(isSuccessful ? "positive" : "ashen", isSuccessful ? 4 : 3)};
  &:after {
    opacity: 1;
  }
`;

export const Input = styled.input`
  visibility: hidden;
  position: absolute;
  &:checked {
    + label {
      ${checkedStyles}
    }
  }
`;

export const Wrapper = styled(Flex)<{ isDisabled: boolean }>`
  label {
    cursor: pointer;
  }
  ${({ isDisabled }) => (isDisabled ? disabledStyles : "")};
`;

export const Label = styled.label.withConfig({
  shouldForwardProp:
    createShouldForwardProp<LabelHTMLAttributes<HTMLLabelElement>>("isChecked"),
})`
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.family.default.medium};
  color: ${({ theme }) => theme.getColor("typography", 0)};
  font-size: 14px;
`;

export const Radio = styled.label.withConfig({
  shouldForwardProp: createShouldForwardProp<
    LabelHTMLAttributes<HTMLLabelElement>
  >("isChecked", "isSuccessful"),
})<{ isSuccessful?: boolean }>`
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid
    ${({ theme, isSuccessful }) =>
      theme.getColor(isSuccessful ? "positive" : "neutral")};
  border-radius: 50%;
  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${({ theme, isSuccessful }) =>
      theme.getColor(isSuccessful ? "positive" : "primary")};
    border-radius: 50%;
  }
`;
