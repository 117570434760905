export default function createShouldForwardProp<T>(...args: unknown[]) {
  return function shouldForwardProp(
    prop?: "ref" | "key" | keyof T,
    defaultValidatorFn?: (prop: "ref" | "key" | keyof T) => boolean
  ) {
    return Boolean(
      !args.includes(prop) &&
        defaultValidatorFn &&
        prop &&
        defaultValidatorFn(prop)
    );
  };
}
