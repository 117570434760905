import styled from "styled-components";
import {
  space,
  layout,
  color,
  typography,
  position,
  border,
} from "styled-system";
import { HTMLAttributes } from "react";
import createShouldForwardProp from "../../../utils/createShouldForwardProp";

export const Box = styled.div.withConfig({
  shouldForwardProp:
    createShouldForwardProp<HTMLAttributes<HTMLDivElement>>("color"),
})`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${border}
`;
