import styled, { keyframes } from "styled-components";
import { Props } from "./Splash";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const shimmer = keyframes`
  from {
    opacity: 0.1;
  }
  50% {
    opacity: 0.15;
  }
  to {
    opacity: 0.1;
  }
`;

export const Animation = styled.div<Pick<Props, "height">>`
  height: ${(props) => props.height};
`;

export const Placeholder = styled.div<{ width: string }>`
  animation: ${shimmer} 2s infinite ease-in-out;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.1));
  border-radius: 0.25rem;
  color: transparent;
  margin-bottom: 0.125rem;
  width: ${({ width }) => width};
`;
