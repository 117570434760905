import { useQuery } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { Queries } from "../../common/queries";
import { TTeam } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { useUser } from "../User";

export const useTeams = () => {
  const { addToast } = useToasts();
  const handleGetTeams = useRequestHandler();
  const { data: userData } = useUser();

  return useQuery<TTeam[]>(Queries.Teams, handleGetTeams, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      addToast("Error getting user teams", {
        appearance: "error",
      });
    },
    enabled: Boolean(userData?.user?.id),
  });
};
