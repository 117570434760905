import { MenuItemType } from "../../../components/DropdownMenu/DropdownMenu.display";
import { UserRoles } from "../../../api/modules/TeamMembers/types";

type Props = {
  isLoadingUserAction: boolean;
  isCurrentUser: boolean;
  currentUserRole: UserRoles;
  role: UserRoles;
  onRemoveUser: () => void;
  onTransferOwnership: () => void;
  onChangeRole: (role: UserRoles) => () => void;
  isVerified: boolean;
};
export const getUserActions = ({
  currentUserRole,
  isCurrentUser,
  role,
  onChangeRole,
  onRemoveUser,
  onTransferOwnership,
  isLoadingUserAction,
  isVerified,
}: Props): MenuItemType[] => {
  const items: MenuItemType[] = [];
  if (role !== UserRoles.Owner) {
    if (!isCurrentUser) {
      items.push({
        label: "Remove",
        onClick: onRemoveUser,
        isDisabled: isLoadingUserAction,
      });
    }
    if (currentUserRole === UserRoles.Owner && isVerified) {
      items.push({
        label: "Transfer Ownership",
        onClick: onTransferOwnership,
        isDisabled: isLoadingUserAction,
      });
    }
  }
  if (role === UserRoles.Admin && !isCurrentUser) {
    items.push({
      label: "Make Member",
      onClick: onChangeRole(UserRoles.Member),
      isDisabled: isLoadingUserAction,
    });
  }
  if (role === UserRoles.Member && !isCurrentUser) {
    items.push({
      label: "Make Admin",
      onClick: onChangeRole(UserRoles.Admin),
      isDisabled: isLoadingUserAction,
    });
  }
  return items;
};
