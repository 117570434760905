import * as Sentry from "@sentry/react";

import { isGenericError } from "./isGenericError";
import { BROWSER_ENVIRONMENT } from "../../constants/global";

const DEV = BROWSER_ENVIRONMENT === "development";

export const Logger = (() => {
  // Logs messages as info
  function info(message: string, extra: any = undefined) {
    try {
      if (DEV) {
        console.log(message, extra);
      } else {
        Sentry.captureMessage(message, {
          level: "info",
          extra,
        });
      }
    } catch (e) {}
  }

  // Logs errors to Sentry
  function error(err: unknown, extra?: { [key: string]: any }) {
    if (isGenericError(err)) return; // filter out generic errors (made for user display only)

    try {
      if (DEV) {
        console.log(err);
      } else {
        Sentry.captureException(err, extra ? { extra } : undefined);
      }
    } catch (e) {}
  }

  return {
    info,
    error,
  };
})();
