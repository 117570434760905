import { useCallback } from "react";

import { useGet } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import type { TResponse } from "./types";
import { isSuccessResponse } from "../../../types/common/Api";

export const useRequestHandler = () => {
  const get = useGet<TResponse>();

  return useCallback(async () => {
    const response = await get({
      path: Endpoints.getUser,
    });
    if (isSuccessResponse(response)) {
      return response.result;
    }
    throw new Error(response.messages?.[0] || "");
  }, [get]);
};
