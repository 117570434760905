import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TGoogleSignInBody, TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { useAuthContext } from "../../../contexts/AuthContext";
import { isSuccessResponse } from "../../../types/common/Api";

export const useGoogleSignIn = () => {
  const { addToast } = useToasts();
  const handleGoogleSignIn = useRequestHandler();
  const { setToken } = useAuthContext();
  return useMutation<TResponse, unknown, TGoogleSignInBody>(
    Mutations.GoogleLogin,
    (payload) => handleGoogleSignIn(payload),
    {
      onError: () => {
        addToast("Authorization error", {
          appearance: "error",
        });
      },
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          setToken(response.result || "");
        }
      },
    }
  );
};
