import { UserRoles } from "../api/modules/TeamMembers/types";
import { Permissions } from "../types/common/Permissions";

export const permissions = {
  [Permissions.ManagePayments]: {
    [UserRoles.Owner]: false, // for now all payments are managed by contacting with Brain.FM
    [UserRoles.Admin]: false,
    [UserRoles.Member]: false,
  },
  [Permissions.ManageDomains]: {
    [UserRoles.Owner]: true,
    [UserRoles.Admin]: false,
    [UserRoles.Member]: false,
  },
  [Permissions.RemoveTeam]: {
    [UserRoles.Owner]: true,
    [UserRoles.Admin]: false,
    [UserRoles.Member]: false,
  },
  [Permissions.UserManagement]: {
    [UserRoles.Owner]: true,
    [UserRoles.Admin]: true,
    [UserRoles.Member]: false,
  },
};
