import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type { CredentialResponse } from "google-one-tap";
import { LoginFormType } from "./Login.types";
import { LoginPresentation } from "./Login.presentation";
import { Routing } from "../../constants/routing";
import { useAuthorizeUser } from "../../api/modules/AuthorizeUser";
import { useInitializeGoogleAuth } from "../../hooks/useInitializeGoogleAuth";
import { useInitializeAppleAuth } from "../../hooks/useInitializeAppleAuth";
import { useGoogleSignIn } from "../../api/modules/GoogleSignIn";
import { useAppleSignIn } from "../../api/modules/AppleSignIn";
import { useAuthContext } from "../../contexts/AuthContext";
import { isSuccessResponse } from "../../types/common/Api";
import { useFacebookSignIn } from "../../api/modules/FacebookSignIn";
import { FacebookStatusResponse } from "../../api/modules/FacebookSignIn/types";

export const Login = () => {
  const navigate = useNavigate();
  const { mutateAsync: handleMutateGoogleSignIn, isLoading: isLoadingGoogle } =
    useGoogleSignIn();
  const { mutateAsync: handleMutateAppleSignIn, isLoading: isLoadingApple } =
    useAppleSignIn();
  const {
    mutateAsync: handleMutateFacebookSignIn,
    isLoading: isLoadingFacebook,
  } = useFacebookSignIn();
  const { isAuthorized } = useAuthContext();
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isAuthorized) return;
    navigate(Routing.Root);
  }, [navigate]);

  const { mutateAsync, isLoading: isLoadingCredentials } = useAuthorizeUser();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm<LoginFormType>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit(async (data) => {
    setFormErrorMessage(null);
    await mutateAsync(
      {
        email: data.email,
        password: data.password,
      },
      {
        onSuccess: (res) => {
          if (isSuccessResponse(res)) {
            navigate(Routing.Root);
            return;
          }
          setFormErrorMessage(res.messages?.[0] || "Authorization Error");
        },
      }
    );
  });

  const handleGoogleSignIn = useCallback(
    (response: CredentialResponse) => {
      if (!response.credential) return;
      handleMutateGoogleSignIn(
        { accessToken: response.credential },
        {
          onSuccess: (res) => {
            if (isSuccessResponse(res)) {
              navigate(Routing.Root);
              return;
            }
            setFormErrorMessage(res.messages?.[0] || "Authorization Error");
          },
        }
      ).catch((e) => {
        console.error(e);
      });
    },
    [navigate, handleMutateGoogleSignIn]
  );

  useInitializeGoogleAuth({
    callback: handleGoogleSignIn,
  });
  useInitializeAppleAuth();

  const handleChangeAnyField = useCallback(() => {
    setFormErrorMessage(null);
  }, [setFormErrorMessage]);

  const handleAppleSignIn = useCallback(async () => {
    const response = await window.AppleID.auth.signIn();
    if (!response?.authorization?.id_token) return;
    await handleMutateAppleSignIn(
      { accessToken: response.authorization.id_token },
      {
        onSuccess: (res) => {
          if (isSuccessResponse(res)) {
            navigate(Routing.Root);
            return;
          }
          setFormErrorMessage(res.messages?.[0] || "Authorization Error");
        },
      }
    );
  }, [navigate, handleMutateAppleSignIn]);
  const handleFacebookCallback = ({ authResponse }: FacebookStatusResponse) => {
    if (authResponse && authResponse.accessToken) {
      handleMutateFacebookSignIn(
        {
          accessToken: authResponse.accessToken,
          userID: authResponse.userID,
        },
        {
          onSuccess: (res) => {
            if (isSuccessResponse(res)) {
              navigate(Routing.Root);
              return;
            }
            setFormErrorMessage(res.messages?.[0] || "Authorization Error");
          },
        }
      ).catch((e) => console.error(e));
    }
  };

  const handleFacebookSignIn = useCallback(() => {
    FB.login(handleFacebookCallback, { scope: "public_profile,email" });
  }, [navigate, handleMutateFacebookSignIn]);

  const isLoading =
    isLoadingApple ||
    isLoadingCredentials ||
    isLoadingGoogle ||
    isLoadingFacebook;

  return (
    <LoginPresentation
      formErrorMessage={formErrorMessage}
      touchedFields={touchedFields}
      errors={errors}
      isValid={isValid}
      isLoading={isLoading}
      register={register}
      onAppleSignIn={handleAppleSignIn}
      onFacebookSignIn={handleFacebookSignIn}
      onSubmit={onSubmit}
      onChangeAnyField={handleChangeAnyField}
    />
  );
};
