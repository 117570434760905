import { useQuery } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { Queries } from "../../common/queries";
import { SuccessResult } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { useUser } from "../User";
import { useTeams } from "../Teams";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { WEB_APP_URL } from "../../../constants/global";

export const useTeamMembers = () => {
  const { addToast } = useToasts();
  const { data: teams } = useTeams();

  const handleGetTeamMembers = useRequestHandler();
  const { data: userData } = useUser();

  return useQuery<SuccessResult>(
    Queries.TeamMembers,
    handleGetTeamMembers(teams?.[0]?.id || "") as () => Promise<SuccessResult>,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: (err) => {
        // if user belongs to a team and do not have permissions to see users, then he should be redirected to web-app
        if (getErrorMessage(err).includes("not authorized")) {
          window.location.replace(WEB_APP_URL);
        }
        addToast("Error getting team members", {
          appearance: "error",
        });
      },
      enabled: Boolean(userData?.user?.id) && Boolean(teams?.[0]?.id),
    }
  );
};
