import { TGenericResponse } from "../../../types/common/Api";

export type TTeamMember = {
  id: string;
  email: string;
  firstName: string;
  role: UserRoles;
  isVerified: boolean;
};
export type TPendingTeamMember = {
  email: string;
  role: UserRoles;
  link: string;
};

export enum UserRoles {
  Member = "member",
  Admin = "admin",
  Owner = "owner",
}

export type SuccessResult = {
  teamMembers: TTeamMember[];
  pendingTeamMembers: TPendingTeamMember[];
};

export type TResponse = TGenericResponse<{
  result: SuccessResult;
}>;
