import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { Routing } from "./constants/routing";
import Layout from "./components/Layout";
import Login from "./containers/Login";
import PaymentSuccess from "./containers/PaymentSuccess";
import PaymentError from "./containers/PaymentError";
import VerifyEmail from "./containers/VerifyEmail";
import { useAuthContext } from "./contexts/AuthContext";
import MyTeam from "./containers/MyTeam";
import UserManagement from "./containers/UserManagement";
import AddUser from "./containers/AddUser";
import { VerifiedEmailRoutes } from "./components/VerifiedEmailRoutes";

function App() {
  const { isAuthDataReady } = useAuthContext();

  if (!isAuthDataReady) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={Routing.Login} />
        <Route element={<PaymentSuccess />} path={Routing.PaymentSuccess} />
        <Route element={<PaymentError />} path={Routing.PaymentError} />
        <Route element={<ProtectedRoutes />} path={Routing.Root}>
          <Route element={<VerifyEmail />} path={Routing.Verify} />
          <Route element={<VerifiedEmailRoutes />} path={Routing.Root}>
            <Route element={<Layout />} path={Routing.Root}>
              <Route element={<MyTeam />} path={Routing.Root} />
              <Route element={<UserManagement />} path={Routing.Users} />
              <Route element={<AddUser />} path={Routing.AddUser} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
