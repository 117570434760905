import styled, { css } from "styled-components";
import { InputHTMLAttributes } from "react";
import createShouldForwardProp from "../../../utils/createShouldForwardProp";
import Flex from "../../LayoutComponents/Flex";

export const IconWrapper = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`;

const disabledStyles = css`
  pointer-events: none;
  background-color: ${({ theme }) => theme.getColor("ashen", 3)};
`;

export const Input = styled.input.withConfig({
  shouldForwardProp: createShouldForwardProp<
    InputHTMLAttributes<HTMLInputElement>
  >(
    "borderRadius",
    "fontSize",
    "padding",
    "hasValue",
    "isDisabled",
    "hasError",
    "hasErrorIcon",
    "inputSize",
    "background"
  ),
})<{
  borderRadius?: string;
  fontSize?: string;
  padding?: string;
  background?: string;
  hasValue?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
}>`
  border: 1px solid;
  border-color: ${({ theme, hasError }) =>
    theme.getColor(hasError ? "negative" : "typography", 2)};
  background: ${({ background }) => background || "transparent"};
  padding: ${({ padding }) => padding || "12px 16px 12px"};
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-family: ${({ theme }) => theme.fonts.family.default.medium};
  color: ${({ theme }) => theme.getColor("typography", -2)};
  ${({ isDisabled }) => (isDisabled ? disabledStyles : "")}
  outline: none;
  &:focus {
    border-color: ${({ theme }) => theme.getColor("primary")};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  ::placeholder {
    color: ${({ theme }) => theme.getColor("typography")};
    opacity: 0.5;
  }
`;
