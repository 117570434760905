import { Routing } from "../../constants/routing";
import { Navigate, Outlet } from "react-router-dom";
import * as React from "react";

import { Splash } from "../../containers/Splash";
import Flex from "../LayoutComponents/Flex";
import { useUser } from "../../api/modules/User";

export const VerifiedEmailRoutes = (): React.ReactElement | null => {
  const { data: userData, isFetched } = useUser();
  if (userData?.verification && !userData.verification?.isVerified) {
    return <Navigate to={Routing.Verify} />;
  }
  if (!isFetched) {
    return (
      <Flex height="100vh">
        <Splash uniqueId="initial" height="300px" />
      </Flex>
    );
  }

  return <Outlet />;
};
