export const Mutations = {
  Login: ["login"],
  GoogleLogin: ["googleLogin"],
  AppleLogin: ["appleLogin"],
  FacebookLogin: ["facebookLogin"],
  CreateTeam: ["CreateTeam"],
  UpdateTeam: ["UpdateTeam"],
  DeleteTeam: ["DeleteTeam"],
  DeleteUser: ["DeleteUser"],
  UpdateUserRole: ["UpdateUserRole"],
  TransferOwnership: ["TransferOwnership"],
  CheckoutSession: ["CheckoutSession"],
  ManageBilling: ["ManageBilling"],
  CreateSubscription: ["CreateSubscription"],
  AddUser: ["AddUser"],
  BulkAddUsers: ["BulkAddUsers"],
  AddDomain: ["AddDomain"],
  RemoveDomain: ["RemoveDomain"],
};
