import { useCallback } from "react";

import { usePost } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import {
  TResendVerificationEmailResponse,
  TResendVerificationEmailRequestBody,
} from "./types";
import { useUser } from "../User";

export const useRequestHandler = () => {
  const { data: userData } = useUser();
  const post = usePost<
    TResendVerificationEmailRequestBody,
    TResendVerificationEmailResponse
  >();

  return useCallback(async () => {
    return post({
      path: Endpoints.resendVerificationEmail(userData?.user?.id || ""),
      body: {},
      options: { apiVersion: 3 },
    });
  }, [post]);
};
