import React from "react";

import { Props, TextVariant } from "./Text.types";
import { getTextElement } from "./lib/getTextElement";

const Text = ({
  variant = TextVariant.body3,
  color,
  className,
  ...props
}: Props) => {
  const TextElement = getTextElement(variant);

  return (
    <TextElement
      className={className}
      color={color}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      fontSize={props.fontSize}
      fontStyle={props.fontStyle}
      fontWeight={props.fontWeight}
      fontFamily={props.fontFamily}
      id={props.id}
      lineHeight={props.lineHeight}
      shouldTruncate={props.shouldTruncate}
      textAlign={props.textAlign}
      title={props.title}
      letterSpacing={props.letterSpacing}
      textTransform={props.textTransform}
    >
      {props.children}
    </TextElement>
  );
};

Text.H1 = (props: Props) => <Text variant={TextVariant.h1} {...props} />;
Text.H2 = (props: Props) => <Text variant={TextVariant.h2} {...props} />;
Text.H3 = (props: Props) => <Text variant={TextVariant.h3} {...props} />;
Text.H4 = (props: Props) => <Text variant={TextVariant.h4} {...props} />;
Text.H5 = (props: Props) => <Text variant={TextVariant.h5} {...props} />;
Text.H6 = (props: Props) => <Text variant={TextVariant.h6} {...props} />;
Text.Body1 = (props: Props) => <Text variant={TextVariant.body1} {...props} />;
Text.Body2 = (props: Props) => <Text variant={TextVariant.body2} {...props} />;
Text.Body3 = (props: Props) => <Text variant={TextVariant.body3} {...props} />;
Text.Body4 = (props: Props) => <Text variant={TextVariant.body4} {...props} />;
Text.Caption = (props: Props) => (
  <Text variant={TextVariant.caption} {...props} />
);

export default Text;
