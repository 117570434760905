import { ReactNode, useRef } from "react";
import { Transition } from "react-transition-group";

import * as S from "./DropdownMenu.styles";
import OutsideClickDetector from "../../utils/OutsideClickDetector";
import Flex from "../LayoutComponents/Flex";
import Text from "../TypographyComponents/Text";

export type MenuItemType = {
  testid?: string;
  urlPath?: string | null;
  label: string;
  onClick?: () => void;
  href?: string;
  isDisabled?: boolean;
};

export interface Props {
  children: ReactNode;
  isActive?: boolean;
  menuItems?: MenuItemType[];
  onClick?: () => void;
  onOutsideClick?: () => void;
}

export const DropdownMenuDisplay = ({
  children,
  onClick = () => {},
  onOutsideClick = () => {},
  isActive = false,
  menuItems = [],
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const hoveredIconStyle = {
    border: "1px solid #565487",
    background: "#191736",
  };

  return (
    <S.Container>
      <OutsideClickDetector onOutsideClick={onOutsideClick}>
        {menuItems.length > 0 && (
          <Flex
            style={isActive ? hoveredIconStyle : undefined}
            onClick={onClick}
            borderRadius="50%"
            border="1px solid transparent"
          >
            {children}
          </Flex>
        )}

        <Transition
          in={isActive}
          mountOnEnter
          nodeRef={menuRef}
          timeout={0}
          unmountOnExit
        >
          {(state) => {
            return (
              <S.MenuContainer ref={menuRef} animationState={state}>
                <S.MenuItemContainer>
                  {menuItems.map((item) => (
                    <S.MenuItem
                      key={item.label}
                      data-testid={item.testid}
                      href={item.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={item.onClick}
                      disabled={item.isDisabled}
                    >
                      <Text.Body3>{item.label}</Text.Body3>
                    </S.MenuItem>
                  ))}
                </S.MenuItemContainer>
              </S.MenuContainer>
            );
          }}
        </Transition>
      </OutsideClickDetector>
    </S.Container>
  );
};
