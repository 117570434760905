import Panel from "../../../../components/Panel";
import { PresentationProps } from "./TeamSubscriptions.types";
import Text from "../../../../components/TypographyComponents/Text";
import Flex from "../../../../components/LayoutComponents/Flex";
import { CreateSubscriptionForm } from "../CreateSubscriptionForm";
import { AiOutlineWarning as WarningIcon } from "react-icons/ai";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../../../components/FormComponents/Button";
import { SubscriptionPaymentIntervals } from "../../../../api/modules/CheckoutSession/types";
import { PaymentSources } from "../../../../api/modules/Subscriptions/types";

export const TeamSubscriptionsPresentation = (props: PresentationProps) => {
  const {
    isFetched,
    isLoading,
    subscriptions,
    teamMembersAmount,
    hasPermissionManagePayments,
    onCheckout,
    onManageBilling,
  } = props;
  return (
    <Flex mt={3}>
      {(subscriptions || []).map(
        ({
          id,
          numberOfSeats,
          hasPlatformAccess,
          billingInterval,
          paymentStatus,
          isRenewing,
          paidPeriodEnd,
          source,
        }) => (
          <Panel key={id}>
            <Flex flexDirection="column" width="100%">
              <Text.Body3
                fontWeight={300}
                color="white"
                letterSpacing="3px"
                textTransform="uppercase"
              >
                your subscription includes
              </Text.Body3>
              <Text.Body2>{numberOfSeats} seats</Text.Body2>
              <Text.Body2>status: {paymentStatus}</Text.Body2>
              {paidPeriodEnd && (
                <>
                  <Text.Body2>
                    {source === PaymentSources.Internal ? "active" : "paid"}{" "}
                    until: {new Date(paidPeriodEnd).toLocaleDateString()}
                  </Text.Body2>
                  <Text.Body2>
                    billing period: {billingInterval || "-"}
                  </Text.Body2>
                  <Text.Body2>
                    auto-renewal: {isRenewing ? "active" : "disabled"}
                  </Text.Body2>
                </>
              )}

              {teamMembersAmount > numberOfSeats && (
                <Flex mt={3} gap="15px" alignItems="center">
                  <WarningIcon fontSize={44} color="white" />
                  <Text.Body1>
                    Your team has more users added than your subscription
                    includes. Please add more seats under "manage billing"
                    section or remove some users.
                  </Text.Body1>
                </Flex>
              )}
              {!hasPlatformAccess && hasPermissionManagePayments && (
                <Flex width="100%" mt={3} justifyContent="flex-end">
                  <Flex ml={3}>
                    <Button
                      variant={ButtonVariants.Outline}
                      onClick={onCheckout(
                        id,
                        SubscriptionPaymentIntervals.Monthly
                      )}
                      variantState={
                        isLoading
                          ? ButtonVariantStates.Loading
                          : ButtonVariantStates.Idle
                      }
                    >
                      Pay Monthly
                    </Button>
                  </Flex>
                  <Flex ml={3}>
                    <Button
                      variant={ButtonVariants.Outline}
                      onClick={onCheckout(
                        id,
                        SubscriptionPaymentIntervals.Yearly
                      )}
                      variantState={
                        isLoading
                          ? ButtonVariantStates.Loading
                          : ButtonVariantStates.Idle
                      }
                    >
                      Pay Yearly
                    </Button>
                  </Flex>
                </Flex>
              )}
              {hasPlatformAccess &&
                hasPermissionManagePayments &&
                source !== PaymentSources.Internal && (
                  <Flex width="100%" mt={3} justifyContent="flex-end" ml={3}>
                    <Button
                      variant={ButtonVariants.Outline}
                      onClick={onManageBilling(id)}
                      variantState={
                        isLoading
                          ? ButtonVariantStates.Loading
                          : ButtonVariantStates.Idle
                      }
                    >
                      Manage Payments
                    </Button>
                  </Flex>
                )}
            </Flex>
          </Panel>
        )
      )}
      {isFetched && !subscriptions?.length && (
        <Flex mt={3} flexDirection="column" width="100%">
          <Text.H3>Create a subscription</Text.H3>
          <Panel mt={3}>
            <CreateSubscriptionForm />
          </Panel>
        </Flex>
      )}
    </Flex>
  );
};
