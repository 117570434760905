import styled, { css } from "styled-components";
import { TextareaHTMLAttributes } from "react";
import createShouldForwardProp from "../../../utils/createShouldForwardProp";

const disabledStyles = css`
  pointer-events: none;
  background-color: ${({ theme }) => theme.getColor("ashen", 3)};
`;

export const Textarea = styled.textarea.withConfig({
  shouldForwardProp: createShouldForwardProp<
    TextareaHTMLAttributes<HTMLTextAreaElement>
  >("isDisabled", "hasError", "background"),
})<{ isDisabled?: boolean; hasError?: boolean; background?: string }>`
  width: 100%;
  background: ${({ background }) => background || "transparent"};
  border: 1px solid
    ${({ theme, hasError }) =>
      theme.getColor(hasError ? "negative" : "typography", 2)};
  padding: 20px 16px;
  border-radius: 10px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.family.default.medium};
  color: ${({ theme }) => theme.getColor("typography", -2)};
  outline: none;
  resize: none;
  &:focus {
    border-color: ${({ theme }) => theme.getColor("primary")};
  }
  ${({ isDisabled }) => (isDisabled ? disabledStyles : "")}
  ::placeholder {
    color: ${({ theme }) => theme.getColor("typography")};
    opacity: 0.5;
  }
`;
