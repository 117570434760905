import { ColorLevel, ColorName, colorPalette, Pallete } from "../../palette";
import { ColorsWithLevels } from "../../palette/types";

export const palette: Pallete = {
  ...colorPalette,
  alert: colorPalette.orange,
  background: colorPalette.white,
  negative: colorPalette.red,
  neutral: colorPalette.gray,
  positive: colorPalette.mint,
  primary: colorPalette.purple,
  warning: colorPalette.yellow,
  typography: colorPalette.white,
};

const getDepthCode = (depth: string) => {
  switch (depth) {
    case "4":
      return "100";
    case "3":
      return "200";
    case "2":
      return "300";
    case "1":
      return "400";
    case "0":
      return "500";
    case "-1":
      return "600";
    case "-2":
      return "700";
    case "-3":
      return "800";
    case "-4":
      return "900";
    default:
      return "500";
  }
};

export const colors = Object.keys(palette).reduce<ColorsWithLevels>(
  (acc, colorKey) => {
    Object.entries(palette[colorKey]).forEach(([depth, data]) => {
      acc[`${colorKey}_${getDepthCode(depth)}`] = data.hex;
    });
    acc[colorKey] = palette[colorKey]["0"].hex;
    return acc;
  },
  {}
);

export const getColor = (type: ColorName, value: ColorLevel = 0) => {
  if (!palette[type][value]) {
    if (import.meta.env.BROWSER_ENVIRONMENT === "development") {
      console.error(
        `Warning: type '${type}' with value '${value}' not found, defaulting to 0`
      );
    }

    return palette[type][0].hex;
  }

  return palette[type][value].hex;
};
