import { ColorLevel, ColorName, HexColor, Pallete } from "./types";

export const colorPalette: Pallete = {
  white: {
    4: { hex: "#FFFFFF", name: "white" },
    3: { hex: "#FFFFFF", name: "white" },
    2: { hex: "#FFFFFF", name: "white" },
    1: { hex: "#FFFFFF", name: "white" },
    0: { hex: "#FFFFFF", name: "white" },
    "-1": { hex: "#FFFFFF", name: "white" },
    "-2": { hex: "#FFFFFF", name: "white" },
    "-3": { hex: "#FFFFFF", name: "white" },
    "-4": { hex: "#FFFFFF", name: "white" },
  },
  rose: {
    4: { hex: "#FFF0F0", name: "red 4" },
    3: { hex: "#F8B1B1", name: "red 3" },
    2: { hex: "#E06666", name: "red 2" },
    1: { hex: "#E84B4B", name: "red 1" },
    0: { hex: "#CB0000", name: "red 0" },
    "-1": { hex: "#980000", name: "red -1" },
    "-2": { hex: "#690E0E", name: "red -2" },
    "-3": { hex: "#431C1C", name: "red -3" },
    "-4": { hex: "#290C0C", name: "red -4" },
  },
  indigo: {
    4: { hex: "#EFF4FC", name: "blue 4" },
    3: { hex: "#C3DAF9", name: "blue 3" },
    2: { hex: "#74ABF1", name: "blue 2" },
    1: { hex: "#468FED", name: "blue 1" },
    0: { hex: "#1873E8", name: "blue 0" },
    "-1": { hex: "#1256AE", name: "blue -1" },
    "-2": { hex: "#0C3A74", name: "blue -2" },
    "-3": { hex: "#072A55", name: "blue -3" },
    "-4": { hex: "#061D3A", name: "blue -4" },
  },
  amber: {
    4: { hex: "#FFFAEE", name: "yellow 4" },
    3: { hex: "#F7E3B5", name: "yellow 3" },
    2: { hex: "#FACF68", name: "yellow 2" },
    1: { hex: "#F8BF36", name: "yellow 1" },
    0: { hex: "#F6AF04", name: "yellow 0" },
    "-1": { hex: "#B98303", name: "yellow -1" },
    "-2": { hex: "#7B5802", name: "yellow -2" },
    "-3": { hex: "#523B02", name: "yellow -3" },
    "-4": { hex: "#3E2C01", name: "yellow -4" },
  },
  leafy: {
    4: { hex: "#F5FFF9", name: "leafy 4" },
    3: { hex: "#C6EFD9", name: "leafy 3" },
    2: { hex: "#77CEA0", name: "leafy 2" },
    1: { hex: "#4ABE81", name: "leafy 1" },
    0: { hex: "#1DAE61", name: "leafy 0" },
    "-1": { hex: "#168349", name: "leafy -1" },
    "-2": { hex: "#0F5731", name: "leafy -2" },
    "-3": { hex: "#07391E", name: "leafy -3" },
    "-4": { hex: "#072C18", name: "leafy -4" },
  },
  apricot: {
    4: { hex: "#FFF7EE", name: "orange 4" },
    3: { hex: "#FDDFB8", name: "orange 3" },
    2: { hex: "#FFBD66", name: "orange 2" },
    1: { hex: "#FFA733", name: "orange 1" },
    0: { hex: "#FF9100", name: "orange 0" },
    "-1": { hex: "#BF6D00", name: "orange -1" },
    "-2": { hex: "#804900", name: "orange -2" },
    "-3": { hex: "#5A3301", name: "orange -3" },
    "-4": { hex: "#402400", name: "orange -4" },
  },
  purple: {
    4: { hex: "#FAFAFF", name: "purple 4" },
    3: { hex: "#DAD8F2", name: "purple 3" },
    2: { hex: "#B3B2DF", name: "purple 2" },
    1: { hex: "#8989C5", name: "purple 1" },
    0: { hex: "#6C6BB7", name: "purple 0" },
    "-1": { hex: "#515089", name: "purple -1" },
    "-2": { hex: "#36365C", name: "purple -2" },
    "-3": { hex: "#28284D", name: "purple -3" },
    "-4": { hex: "#1B1B2E", name: "purple -4" },
  },
  aqua: {
    4: { hex: "#F5FFFD", name: "aqua 4" },
    3: { hex: "#B8F0E6", name: "aqua 3" },
    2: { hex: "#7EE2CF", name: "aqua 2" },
    1: { hex: "#40C8AE", name: "aqua 1" },
    0: { hex: "#10BA9A", name: "aqua 0" },
    "-1": { hex: "#0C8B73", name: "aqua -1" },
    "-2": { hex: "#085D4D", name: "aqua -2" },
    "-3": { hex: "#044539", name: "aqua -3" },
    "-4": { hex: "#042F27", name: "aqua -4" },
  },
  brown: {
    4: { hex: "#FFFBF8", name: "brown 4" },
    3: { hex: "#DECFC0", name: "brown 3" },
    2: { hex: "#AA927A", name: "brown 2" },
    1: { hex: "#8E6E4D", name: "brown 1" },
    0: { hex: "#724A21", name: "brown 0" },
    "-1": { hex: "#563819", name: "brown -1" },
    "-2": { hex: "#392511", name: "brown -2" },
    "-3": { hex: "#2D1F0D", name: "brown-3" },
    "-4": { hex: "#1C1308", name: "brown -4" },
  },
  ashen: {
    4: { hex: "#FFFFFF", name: "gray 4" },
    3: { hex: "#F8F8F9", name: "gray 3" },
    2: { hex: "#E8EEF0", name: "gray 2" },
    1: { hex: "#CBD5DA", name: "gray 1" },
    0: { hex: "#AFBBC3", name: "gray 0" },
    "-1": { hex: "#97A4A9", name: "gray -1" },
    "-2": { hex: "#5B6B70", name: "gray -2" },
    "-3": { hex: "#293538", name: "gray -3" },
    "-4": { hex: "#202526", name: "gray -4" },
  },
  black: {
    4: { hex: "#3c3f42", name: "black 4" },
    3: { hex: "#313437", name: "black 3" },
    2: { hex: "#27292b", name: "black 2" },
    1: { hex: "#1d1f20", name: "black 1" },
    0: { hex: "#060F1D", name: "black 0" },
    "-1": { hex: "#080909", name: "black -1" },
    "-2": { hex: "#050606", name: "black -2" },
    "-3": { hex: "#030303", name: "black -3" },
    "-4": { hex: "#000000", name: "black -4" },
  },
  gray: {
    4: { hex: "#FFFFFF", name: "gray 4" },
    3: { hex: "#e6e9ec", name: "gray 3" },
    2: { hex: "#dee2e6", name: "gray 2" },
    1: { hex: "#ced4da", name: "gray 1" },
    0: { hex: "#BDC5CD", name: "gray 0" },
    "-1": { hex: "#9da9b5", name: "gray -1" },
    "-2": { hex: "#7d8d9c", name: "gray -2" },
    "-3": { hex: "#617180", name: "gray -3" },
    "-4": { hex: "#495460", name: "gray -4" },
  },
  orange: {
    4: { hex: "#fdcead", name: "orange 4" },
    3: { hex: "#fdbe91", name: "orange 3" },
    2: { hex: "#fc9d5a", name: "orange 2" },
    1: { hex: "#fb8c3f", name: "orange 1" },
    0: { hex: "#FB7C23", name: "orange 0" },
    "-1": { hex: "#ea6304", name: "orange -1" },
    "-2": { hex: "#bb4f03", name: "orange -2" },
    "-3": { hex: "#8c3b03", name: "orange -3" },
    "-4": { hex: "#5e2802", name: "orange -4" },
  },
  green: {
    4: { hex: "#b3ecd0", name: "green 4" },
    3: { hex: "#a1e7c4", name: "green 3" },
    2: { hex: "#8ee3b8", name: "green 2" },
    1: { hex: "#7bdeac", name: "green 1" },
    0: { hex: "#68D9A0", name: "green 0" },
    "-1": { hex: "#3dce85", name: "green -1" },
    "-2": { hex: "#2bab6a", name: "green -2" },
    "-3": { hex: "#208050", name: "green -3" },
    "-4": { hex: "#165535", name: "green -4" },
  },
  mint: {
    4: { hex: "#caf1de", name: "mint 4" },
    3: { hex: "#84dabb", name: "mint 3" },
    2: { hex: "#70d4b0", name: "mint 2" },
    1: { hex: "#47c899", name: "mint 1" },
    0: { hex: "#38BC8C", name: "mint 0" },
    "-1": { hex: "#2d9670", name: "mint -1" },
    "-2": { hex: "#227154", name: "mint -2" },
    "-3": { hex: "#164b38", name: "mint -3" },
    "-4": { hex: "#11382a", name: "mint -4" },
  },
  blue: {
    4: { hex: "#EFF6FF", name: "blue 4" },
    3: { hex: "#90bcff", name: "blue 3" },
    2: { hex: "#7aafff", name: "blue 2" },
    1: { hex: "#63a1ff", name: "blue 1" },
    0: { hex: "#4D94FF", name: "blue 0" },
    "-1": { hex: "#0b6cff", name: "blue -1" },
    "-2": { hex: "#004fc7", name: "blue -2" },
    "-3": { hex: "#003585", name: "blue -3" },
    "-4": { hex: "#001a42", name: "blue -4" },
  },
  yellow: {
    4: { hex: "#fce2a0", name: "yellow 4" },
    3: { hex: "#fbdb88", name: "yellow 3" },
    2: { hex: "#fbd470", name: "yellow 2" },
    1: { hex: "#facc58", name: "yellow 1" },
    0: { hex: "#F9C540", name: "yellow 0" },
    "-1": { hex: "#f3b108", name: "yellow -1" },
    "-2": { hex: "#b68506", name: "yellow -2" },
    "-3": { hex: "#795804", name: "yellow -3" },
    "-4": { hex: "#3d2c02", name: "yellow -4" },
  },
  red: {
    4: { hex: "#fdc3d1", name: "red 4" },
    3: { hex: "#fc9bb2", name: "red 3" },
    2: { hex: "#fb7293", name: "red 2" },
    1: { hex: "#fa4a74", name: "red 1" },
    0: { hex: "#F93665", name: "red 0" },
    "-1": { hex: "#f8194e", name: "red -1" },
    "-2": { hex: "#eb073e", name: "red -2" },
    "-3": { hex: "#b1052f", name: "red -3" },
    "-4": { hex: "#930427", name: "red -4" },
  },
  pale: {
    // project specific
    4: { hex: "#ffffff", name: "red 4" },
    3: { hex: "#EAEEF7", name: "red 3" },
    2: { hex: "#E8E8E8", name: "red 2" },
    1: { hex: "#979797", name: "red 1" },
    0: { hex: "#56524F", name: "red 0" },
    "-1": { hex: "#4E546F", name: "red -1" },
    "-2": { hex: "#1D2547", name: "red -2" },
    "-3": { hex: "#14182f", name: "red -3" },
    "-4": { hex: "#252733", name: "red -4" },
  },
};

export type { ColorName, ColorLevel, Pallete, HexColor };
