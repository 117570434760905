import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TAppleSignInBody, TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { useAuthContext } from "../../../contexts/AuthContext";
import { isSuccessResponse } from "../../../types/common/Api";

export const useAppleSignIn = () => {
  const { addToast } = useToasts();
  const handleAppleSignIn = useRequestHandler();
  const { setToken } = useAuthContext();
  return useMutation<TResponse, unknown, TAppleSignInBody>(
    Mutations.AppleLogin,
    (payload) => handleAppleSignIn(payload),
    {
      onError: (err) => {
        addToast((err as Error)?.message || "Authorization Error", {
          appearance: "error",
        });
      },
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          setToken(response.result || "");
        }
      },
    }
  );
};
