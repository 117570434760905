export type TGenericResponse<T> = TErrorResponse | TSuccessResponse<T>;

export type TErrorResponse = {
  status: number;
  messages: string[];
};

export type TSuccessResponse<T> = T & {
  status: number;
};

export function isSuccessResponse<T>(
  response: TErrorResponse | TSuccessResponse<T>
): response is TSuccessResponse<T> {
  return "status" in response && response.status < 300;
}
