import { Link } from "react-router-dom";
import { AiOutlineLogin as LogoutIcon } from "react-icons/ai";
import Flex from "../LayoutComponents/Flex";
import { Routing } from "../../constants/routing";
import * as S from "./Navigation.styles";
import { useLogoutUser } from "../../api/modules/LogoutUser";
import BrandLogo from "../../assets/brand_logo.svg";
import { useUser } from "../../api/modules/User";
import Text from "../TypographyComponents/Text";

export const Navigation = () => {
  const handleLogout = useLogoutUser();
  const { data: userData } = useUser();

  const onLogout = () => {
    const isConfirmed = window.confirm(
      "You are about to log out. Are you sure?"
    );
    if (!isConfirmed) return;
    handleLogout();
  };

  return (
    <S.Wrapper
      alignItems="center"
      justifyContent={["center", "center", "center", "space-between"]}
      position="relative"
      py={5}
      width="100%"
    >
      <Link to={Routing.Root}>
        <Flex alignItems="center" gap="10px">
          <img src={BrandLogo} alt="Brain.FM" width="60px" />
          <Flex
            alignItems="center"
            gap="3px"
            display={["none", "flex", "flex", "flex"]}
          >
            <Text.H3>Teams</Text.H3>
            <Flex
              top="5px"
              position="relative"
              display={["none", "none", "flex", "flex"]}
            >
              <Text.Body3>βeta</Text.Body3>
            </Flex>
          </Flex>
        </Flex>
      </Link>
      <Flex position="absolute" right="0" color="white">
        <Flex mr={3} display={["none", "flex", "flex", "flex"]}>
          <Text.Body3>{userData?.user.firstName || ""}</Text.Body3>
        </Flex>
        <LogoutIcon
          cursor="pointer"
          title="Logout"
          onClick={onLogout}
          size="20px"
        />
      </Flex>
    </S.Wrapper>
  );
};
