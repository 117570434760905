import React, { forwardRef, Ref } from "react";
import * as S from "./Radio.styles";
import { Props } from "./Radio.types";
import Flex from "../../LayoutComponents/Flex";

const RadioComponent = (props: Props, ref?: Ref<HTMLInputElement>) => {
  const {
    id,
    name,
    onBlur,
    onChange,
    isDisabled = false,
    value,
    label,
    isSuccessful,
    defaultChecked,
  } = props;
  return (
    <S.Wrapper alignItems="center" isDisabled={isDisabled} position="relative">
      <S.Input
        ref={ref}
        defaultChecked={defaultChecked}
        id={id || name}
        name={name}
        type="radio"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      <S.Radio htmlFor={id || name} isSuccessful={isSuccessful} />
      {label && (
        <Flex ml={4}>
          <S.Label htmlFor={id || name}>{label}</S.Label>
        </Flex>
      )}
    </S.Wrapper>
  );
};

export const Radio = forwardRef<HTMLInputElement, Props>(RadioComponent);
