import { useUser } from "../api/modules/User";
import { useTeamMembers } from "../api/modules/TeamMembers";
import { UserRoles } from "../api/modules/TeamMembers/types";

export const useUserRole = (): UserRoles => {
  const { data: userData } = useUser();
  const { data: teamMembersData } = useTeamMembers();
  return (
    (teamMembersData?.teamMembers || []).find(
      (member) => member.id === userData?.user?.id
    )?.role || UserRoles.Member
  );
};
