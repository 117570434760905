import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";
import { Queries } from "../../common/queries";

export const useDeleteTeam = () => {
  const { addToast } = useToasts();
  const handleDeleteTeam = useRequestHandler();
  const queryClient = useQueryClient();

  return useMutation<TResponse, unknown, string>(
    Mutations.DeleteTeam,
    (teamId: string) => handleDeleteTeam(teamId),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient
            .invalidateQueries(Queries.Teams)
            .then(() => {
              queryClient.removeQueries(Queries.Subscriptions);
              queryClient.removeQueries(Queries.TeamMembers);
            })
            .catch((e) => console.error(e));

          return;
        }
        addToast("Error deleting a team", {
          appearance: "error",
        });
      },
    }
  );
};
