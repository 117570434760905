import { PresentationProps } from "./AddUser.types";
import * as S from "./AddUser.styles";
import Text from "../../components/TypographyComponents/Text";
import Panel from "../../components/Panel";
import { AddUserForm } from "./components/AddUserForm";
import Flex from "../../components/LayoutComponents/Flex";
import { Link } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { IoMdCloseCircleOutline as CloseIcon } from "react-icons/io";
import Box from "../../components/LayoutComponents/Box";
import {
  AiOutlineCopy as CopyIcon,
  AiOutlineWarning as WarningIcon,
} from "react-icons/ai";
import { BulkAddUsersForm } from "./components/BulkAddUsersForm";

export const AddUserPresentation = (props: PresentationProps) => {
  const { inviteUrl, hasDomainsAdded, onCopyLink } = props;

  return (
    <S.Wrapper flexDirection="column" width="100%">
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Text.H1>Add User</Text.H1>
        <Link to={Routing.Users}>
          <Flex gap="8px" alignItems="flex-end">
            <Text.H6>cancel</Text.H6>
            <CloseIcon fontSize="18px" color="white" />
          </Flex>
        </Link>
      </Flex>
      <Panel mt={3}>
        <Flex flexDirection="column">
          <Text.H5>Share Invite Link</Text.H5>
          <Box mt={2}>
            <Flex
              onClick={onCopyLink}
              alignItems="center"
              style={{ cursor: "pointer" }}
              color="white"
              gap="10px"
            >
              <CopyIcon size="18px" />
              <Text.Body2>{inviteUrl}</Text.Body2>
            </Flex>
            {!hasDomainsAdded && (
              <Flex mt={3} gap="10px" alignItems="center">
                <WarningIcon fontSize={24} color="white" />
                <Text.Body2>
                  Team domain(s) should be added first to use invitation links
                </Text.Body2>
              </Flex>
            )}
          </Box>
        </Flex>
      </Panel>
      <Panel mt={3}>
        <AddUserForm />
      </Panel>
      <Panel mt={3}>
        <BulkAddUsersForm />
      </Panel>
    </S.Wrapper>
  );
};
