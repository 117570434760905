import { PresentationProps } from "./UserManagement.types";
import * as S from "./UserManagement.styles";
import Text from "../../components/TypographyComponents/Text";
import Panel from "../../components/Panel";
import Flex from "../../components/LayoutComponents/Flex";
import { DropdownMenu } from "../../components/DropdownMenu";
import { getUserActions } from "./lib/getUserActions";
import { UserRoles } from "../../api/modules/TeamMembers/types";
import { CiMenuKebab as MenuIcon } from "react-icons/ci";
import {
  AiOutlineCheckCircle as VerifiedIcon,
  AiOutlineCopy as CopyIcon,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Routing } from "../../constants/routing";
import LoadingIcon from "../../components/LoadingComponents/LoadingIcon";
import { IoMdAddCircleOutline as AddIcon } from "react-icons/io";
import Input from "../../components/FormComponents/Input";

export const UserManagementPresentation = (props: PresentationProps) => {
  const {
    isLoadingMembers,
    isLoadingUserAction,
    list,
    pendingList,
    currentUserRole,
    currentUserId,
    onTransferOwnership,
    onRemoveUser,
    onChangeRole,
    onChangeSearchQuery,
    onChangeSearchPendingQuery,
    onCopyLink,
  } = props;
  return (
    <S.Wrapper flexDirection="column" width="100%">
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Text.H1>Team Members</Text.H1>
        <Link to={Routing.AddUser}>
          <Flex gap="8px" alignItems="flex-end">
            <Text.H6>add</Text.H6>
            <AddIcon fontSize="18px" color="white" />
          </Flex>
        </Link>
      </Flex>
      <Panel mt={3} flexDirection="column">
        {isLoadingMembers && (
          <Flex justifyContent="center">
            <LoadingIcon />
          </Flex>
        )}
        {!isLoadingMembers && (
          <>
            <Flex mb={2} maxWidth="300px">
              <Input
                name="searchQuery"
                id="searchQuery"
                label="Search Users"
                onChange={onChangeSearchQuery}
              />
            </Flex>
            <Flex
              gap="10px"
              mt={2}
              mb={4}
              alignItems="center"
              display={["none", "none", "none", "flex"]}
            >
              <Flex width="20px">
                <Text.Body3>#</Text.Body3>
              </Flex>
              <Flex width="120px">
                <Text.Body3>name</Text.Body3>
              </Flex>
              <Flex width="220px" style={{ wordBreak: "break-word" }}>
                <Text.Body3>email</Text.Body3>
              </Flex>
              <Flex width="60px">
                <Text.Body3>role</Text.Body3>
              </Flex>
              <Flex width="30px" />
            </Flex>
          </>
        )}
        {(list || []).map(({ id, role, email, firstName, isVerified }, i) => {
          const isCurrentUser = id === currentUserId;
          const actionsNode = (
            <DropdownMenu
              menuItems={getUserActions({
                isCurrentUser,
                currentUserRole,
                role,
                onTransferOwnership: onTransferOwnership(email),
                onRemoveUser: onRemoveUser(email),
                onChangeRole: onChangeRole(email),
                isLoadingUserAction,
                isVerified,
              })}
            >
              <Flex color="white" padding={1}>
                <MenuIcon cursor="pointer" />
              </Flex>
            </DropdownMenu>
          );
          return (
            <Flex
              key={id}
              gap="10px"
              my={2}
              alignItems="center"
              flexDirection={["column", "column", "column", "row"]}
              borderTop={i > 0 ? "1px solid rgba(55, 62, 91, 0.7)" : ""}
              pt={i > 0 ? 4 : 0}
            >
              <S.TableCell
                width={["100%", "100%", "100%", "20px"]}
                data-label="#"
              >
                <Flex alignItems="center">
                  <Text.Body3>{i + 1}</Text.Body3>
                  <Flex display={["flex", "flex", "flex", "none"]} ml={3}>
                    {role !== UserRoles.Owner && actionsNode}
                  </Flex>
                </Flex>
              </S.TableCell>
              <S.TableCell
                width={["100%", "100%", "100%", "120px"]}
                data-label="name"
              >
                <Text.Body3>{firstName}</Text.Body3>
              </S.TableCell>
              <S.TableCell
                width={["100%", "100%", "100%", "220px"]}
                style={{ wordBreak: "break-word" }}
                data-label="email"
              >
                <Flex color="white" alignItems="center" gap="3px">
                  <Text.Body3>{email}</Text.Body3>
                  {isVerified && <VerifiedIcon title="Verified Email" />}
                </Flex>
              </S.TableCell>
              <S.TableCell
                width={["100%", "100%", "100%", "60px"]}
                data-label="role"
              >
                <Text.Body3>{role}</Text.Body3>
              </S.TableCell>
              <S.TableCell
                display={["none", "none", "none", "flex"]}
                width={["100%", "100%", "100%", "30px"]}
                justifyContent={[
                  "flex-start",
                  "flex-start",
                  "center",
                  "center",
                ]}
                alignItems="center"
                data-label="actions"
              >
                {role !== UserRoles.Owner && actionsNode}
              </S.TableCell>
            </Flex>
          );
        })}
      </Panel>
      <Flex mt={7} flexDirection="column">
        <Flex justifyContent="space-between" alignItems="flex-end">
          <Text.H1>Pending Team Members</Text.H1>
        </Flex>
        <Panel mt={3} flexDirection="column">
          {isLoadingMembers && (
            <Flex justifyContent="center">
              <LoadingIcon />
            </Flex>
          )}
          {!isLoadingMembers && (
            <>
              <Flex mb={2} maxWidth="300px">
                <Input
                  name="searchQuery"
                  id="searchQuery"
                  label="Search Pending Users"
                  onChange={onChangeSearchPendingQuery}
                />
              </Flex>
              <Flex
                gap="10px"
                mt={2}
                mb={4}
                alignItems="center"
                display={["none", "none", "none", "flex"]}
              >
                <Flex width="20px">
                  <Text.Body3>#</Text.Body3>
                </Flex>
                <Flex width="220px">
                  <Text.Body3>email</Text.Body3>
                </Flex>
                <Flex width="60px">
                  <Text.Body3>role</Text.Body3>
                </Flex>
                <Flex>
                  <Text.Body3>link</Text.Body3>
                </Flex>
                <Flex width="30px" />
              </Flex>
            </>
          )}
          {(pendingList || []).map(({ role, email, link }, i) => {
            return (
              <Flex
                key={i}
                gap="10px"
                my={2}
                alignItems="center"
                flexDirection={["column", "column", "column", "row"]}
                borderTop={i > 0 ? "1px solid rgba(55, 62, 91, 0.7)" : ""}
                pt={i > 0 ? 4 : 0}
              >
                <S.TableCell
                  width={["100%", "100%", "100%", "20px"]}
                  data-label="#"
                >
                  <Flex alignItems="center">
                    <Text.Body3>{i + 1}</Text.Body3>
                  </Flex>
                </S.TableCell>
                <S.TableCell
                  width={["100%", "100%", "100%", "220px"]}
                  data-label="name"
                >
                  <Text.Body3>{email}</Text.Body3>
                </S.TableCell>
                <S.TableCell
                  width={["100%", "100%", "100%", "60px"]}
                  style={{ wordBreak: "break-word" }}
                  data-label="email"
                >
                  <Flex color="white" alignItems="center" gap="3px">
                    <Text.Body3>{role}</Text.Body3>
                  </Flex>
                </S.TableCell>
                <S.TableCell
                  width={["100%", "100%", "100%", "240px"]}
                  data-label="link"
                  onClick={onCopyLink(link)}
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                  color="white"
                  gap="10px"
                >
                  <CopyIcon size="28px" />
                  <Text.Body3 shouldTruncate={true}>{link}</Text.Body3>
                </S.TableCell>
              </Flex>
            );
          })}
        </Panel>
      </Flex>
    </S.Wrapper>
  );
};
