import React from "react";
import { Props } from "./Panel.types";
import Flex from "../LayoutComponents/Flex";

export const Panel = (props: Props) => {
  const { children, ...flexProps } = props;
  return (
    <Flex
      backgroundColor="rgba(255, 255, 255, 0.05)"
      border="1px solid rgba(55, 62, 91, 0.7)"
      style={{
        backdropFilter: "blur(2rem)",
        boxShadow: "rgba(25, 23, 54, 0.65) 0px 20px 50px",
      }}
      borderRadius="20px"
      px={7}
      py={7}
      width="100%"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};
