import { AiFillApple as AppleIcon } from "react-icons/ai";
import { TiSocialFacebook as FacebookIcon } from "react-icons/ti";

import { PresentationProps } from "./Login.types";
import Panel from "../../components/Panel";
import Flex from "../../components/LayoutComponents/Flex";
import Form from "../../components/FormComponents/Form";
import Input from "../../components/FormComponents/Input";
import { emailPattern } from "../../utils/emailPattern";
import Text from "../../components/TypographyComponents/Text";
import BrandLogo from "../../assets/brand_logo.svg";
import * as S from "./Login.styles";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../components/FormComponents/Button";

export const LoginPresentation = (props: PresentationProps) => {
  const {
    isValid,
    isLoading,
    register,
    errors,
    touchedFields,
    formErrorMessage,
    onAppleSignIn,
    onFacebookSignIn,
    onSubmit,
    onChangeAnyField,
  } = props;
  return (
    <S.Wrapper
      justifyContent="center"
      minHeight="100vh"
      alignItems="center"
      flexDirection="column"
    >
      <Flex my={6} flexDirection="column" alignItems="center">
        <img src={BrandLogo} alt="Brain.FM" width="80px" />
        <Flex mt={7} gap="5px">
          <Text.H1 color="white">Brain.FM Teams</Text.H1>
          <Flex
            top="15px"
            position="relative"
            display={["none", "none", "flex", "flex"]}
          >
            <Text.Body3>βeta</Text.Body3>
          </Flex>
        </Flex>
      </Flex>

      <Panel alignItems="center" flexDirection="column" mb={5} maxWidth="500px">
        <Form
          flexDirection="column"
          flexGrow={1}
          width="100%"
          onSubmit={onSubmit}
        >
          <Flex alignItems="flex-start" flexDirection="column">
            <Flex
              alignItems="flex-start"
              alignSelf="center"
              flexDirection="column"
              flexGrow={0}
              flexWrap="wrap"
              justifyContent="center"
              mt={3}
              width="100%"
            >
              <Flex
                alignItems="flex-start"
                alignSelf="center"
                flexDirection="column"
                flexGrow={1}
                mt={4}
                width="100%"
              >
                <Input
                  id="email"
                  label="email"
                  isTouched={touchedFields.email}
                  errorMessage={errors.email?.message}
                  {...register("email", {
                    onChange: onChangeAnyField,
                    required: true,
                    pattern: {
                      value: emailPattern,
                      message: "Email is not correct",
                    },
                  })}
                />
              </Flex>
              <Flex
                alignItems="flex-start"
                alignSelf="center"
                flexDirection="column"
                flexGrow={1}
                mt={4}
                width="100%"
              >
                <Input
                  id="password"
                  label="password"
                  type="password"
                  isTouched={touchedFields.password}
                  errorMessage={errors.password?.message}
                  {...register("password", {
                    onChange: onChangeAnyField,
                    required: true,
                  })}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="center" mt={7}>
            <Button
              disabled={!isValid}
              isFullWidth={true}
              type="submit"
              variant={ButtonVariants.Primary}
              variantState={
                isLoading
                  ? ButtonVariantStates.Loading
                  : formErrorMessage
                  ? ButtonVariantStates.Error
                  : ButtonVariantStates.Idle
              }
            >
              {formErrorMessage || "sign in"}
            </Button>
          </Flex>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={7}
          >
            <S.HR />
            <Flex mx={2} minWidth="130px" justifyContent="center">
              <Text.Body3>or sign in with</Text.Body3>
            </Flex>
            <S.HR />
          </Flex>
          <Flex
            alignSelf="center"
            justifyContent="center"
            flexGrow={1}
            alignItems="center"
            mt={4}
            gap="10px"
            flexWrap="wrap"
          >
            <Flex ml={1} width="200px">
              <Button
                onClick={onFacebookSignIn}
                isFullWidth={true}
                variant={ButtonVariants.Tertiary}
                variantState={ButtonVariantStates.Idle}
              >
                <Flex
                  fontSize="14px"
                  alignItems="center"
                  color="white"
                  justifyContent="center"
                >
                  <Flex mr={3}>
                    <FacebookIcon fontSize="22px" />
                  </Flex>
                  <Flex>Facebook</Flex>
                </Flex>
              </Button>
            </Flex>
            <Flex ml={1} width="200px">
              <Button
                onClick={onAppleSignIn}
                isFullWidth={true}
                variant={ButtonVariants.Tertiary}
                variantState={ButtonVariantStates.Idle}
              >
                <Flex
                  fontSize="14px"
                  alignItems="center"
                  color="white"
                  justifyContent="center"
                >
                  <Flex mr={3}>
                    <AppleIcon fontSize="22px" />
                  </Flex>
                  <Flex>Apple</Flex>
                </Flex>
              </Button>
            </Flex>
            <Flex width="200px" height="45px">
              <div id="google-button" />
            </Flex>
          </Flex>
        </Form>
      </Panel>
    </S.Wrapper>
  );
};
