import { TeamDataPresentation } from "./TeamData.presentation";
import { useTeams } from "../../../../api/modules/Teams";
import Flex from "../../../../components/LayoutComponents/Flex";
import { useDeleteTeam } from "../../../../api/modules/DeleteTeam";
import { useCallback, useState } from "react";
import { TeamSubscriptions } from "../TeamSubscriptions";
import { useSubscriptions } from "../../../../api/modules/Subscriptions";
import { useCanRemoveTeam } from "../../../../hooks/useCanRemoveTeam";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { Permissions } from "../../../../types/common/Permissions";

export const TeamData = () => {
  const [isManageDomainsActive, setIsManageDomainsActive] = useState(false);
  const { data } = useTeams();
  const { data: subscriptions } = useSubscriptions();
  const getHasPermission = useHasPermission();
  const canRemoveTeam = useCanRemoveTeam({ subscriptions });
  const canManageDomains = getHasPermission(Permissions.ManageDomains);
  const handleToggleManageDomains = useCallback(() => {
    setIsManageDomainsActive((val) => !val);
  }, [setIsManageDomainsActive]);

  const { mutateAsync, isLoading } = useDeleteTeam();
  const handleRemoveTeam = useCallback(
    (teamId: string) => () => {
      const isConfirmed = window.confirm(
        "You are about to remove your team. Are you sure?"
      );
      if (!isConfirmed) return;
      mutateAsync(teamId).catch((e) => console.error(e));
    },
    [mutateAsync]
  );
  return (
    <Flex mt={3} flexDirection="column">
      {(data || []).map((team) => (
        <TeamDataPresentation
          team={team}
          isLoading={isLoading}
          isManageDomainsActive={isManageDomainsActive}
          key={team.id}
          canRemoveTeam={canRemoveTeam}
          canManageDomains={canManageDomains}
          onRemoveTeam={handleRemoveTeam}
          onToggleManageDomains={handleToggleManageDomains}
        />
      ))}
      <TeamSubscriptions />
    </Flex>
  );
};
