import { Logger } from "../../../utils/logger";
import { Queries } from "../../common/queries";
import { TResendVerificationEmailResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { useMutation } from "@tanstack/react-query";

type Props = {
  callback?: () => void;
};
export const useResendVerificationEmail = (props: Props) => {
  const { callback } = props;
  const postResendVerificationEmail = useRequestHandler();

  return useMutation<TResendVerificationEmailResponse, Error>(
    Queries.resendVerificationEmail,
    () => postResendVerificationEmail(),
    {
      onError: () => {
        Logger.error(new Error("Error sending verification email"));
      },
      onSuccess: (data: TResendVerificationEmailResponse) => {
        if (data.status !== 200) return;
        if (callback) {
          callback();
        }
      },
    }
  );
};
