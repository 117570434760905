import { TextVariant } from "../Text.types";
import * as S from "../Text.styles";

export const getTextElement = (variant: TextVariant) => {
  switch (variant) {
    case TextVariant.h1:
      return S.H1;
    case TextVariant.h2:
      return S.H2;
    case TextVariant.h3:
      return S.H3;
    case TextVariant.h4:
      return S.H4;
    case TextVariant.h5:
      return S.H5;
    case TextVariant.h6:
      return S.H6;
    case TextVariant.body1:
      return S.Body1;
    case TextVariant.body2:
      return S.Body2;
    case TextVariant.body3:
      return S.Body3;
    case TextVariant.body4:
      return S.Body4;
    case TextVariant.caption:
      return S.Caption;
    default:
      return S.Body1;
  }
};
