import { useCallback } from "react";

import { useGet } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import type { TResponse } from "./types";
import { isSuccessResponse } from "../../../types/common/Api";
import { useUser } from "../User";

export const useRequestHandler = () => {
  const get = useGet<TResponse>();
  const { data: userData } = useUser();

  return useCallback(
    (teamId: string) => async () => {
      if (!userData) return [];
      const response = await get({
        path: Endpoints.getSubscriptions(userData?.user?.id || "", teamId),
        options: {
          apiVersion: 3,
        },
      });
      if (isSuccessResponse(response)) {
        return response.result;
      }
      throw new Error(response.messages?.[0] || "");
    },
    [get, userData?.user?.id]
  );
};
