import React from "react";
import { Props } from "./ValidationMessage.types";
import Flex from "../LayoutComponents/Flex";
import Text from "../TypographyComponents/Text";

export const ValidationMessage = (props: Props) => {
  const { errorMessage } = props;
  return (
    <Flex mt={1}>
      <Text.Body4 color="negative">{errorMessage}</Text.Body4>
    </Flex>
  );
};
