import { AddUserPresentation } from "./AddUser.presentation";
import { useInviteUrl } from "../../hooks/useInviteUrl";
import { useCallback } from "react";
import { useTeams } from "../../api/modules/Teams";

export const AddUser = () => {
  const inviteUrl = useInviteUrl();
  const { data: teams } = useTeams();
  const hasDomainsAdded = Boolean((teams?.[0]?.teamDomains || []).length);
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(inviteUrl || "").then(
      () => {
        alert("Link copied to the clipboard");
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }, [inviteUrl]);

  return (
    <AddUserPresentation
      hasDomainsAdded={hasDomainsAdded}
      inviteUrl={inviteUrl}
      onCopyLink={handleCopyLink}
    />
  );
};
