import styled from "styled-components";
import Flex from "../../components/LayoutComponents/Flex";

export const Wrapper = styled(Flex)`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in;
`;

export const TableCell = styled(Flex)`
  &:before {
    font-family: ${({ theme }) => theme.fonts.family.default.regular};
    content: attr(data-label);
    display: flex;
    width: 90px;
    text-align: left;
    justify-content: flex-start;
    color: white;
    ${({ theme }) => theme.mediaQuery.minWidth.md} {
      display: none;
    }
  }
`;
