import { useUserRole } from "./useUserRole";
import { useCallback } from "react";
import { getHasPermission } from "../utils/getHasPermission";
import { Permissions } from "../types/common/Permissions";

export const useHasPermission = () => {
  const role = useUserRole();
  return useCallback(
    (permission: Permissions) => getHasPermission(permission, role),
    [role]
  );
};
