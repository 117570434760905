import { useCallback } from "react";

import { usePost } from "../../client/client";
import { Endpoints } from "../../common/endpoints";
import { TAuthorizeUserBody, TResponse } from "./types";

export const useRequestHandler = () => {
  const post = usePost<TAuthorizeUserBody, TResponse>();

  return useCallback(
    async (payload: TAuthorizeUserBody) => {
      return post({
        path: Endpoints.postAuthorizeUser,
        body: {
          ...payload,
        },
      });
    },
    [post]
  );
};
