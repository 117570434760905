import { TGenericResponse } from "../../../types/common/Api";

export enum PaymentSources {
  Stripe = "stripe",
  Internal = "internal",
}

export type TDetailedSubscription = {
  id: string;
  teamId: string;
  numberOfSeats: number;
  isRenewing: boolean;
  customerId: string;
  externalSubscriptionId: string;
  hasPlatformAccess: boolean;
  billingInterval: string;
  source: PaymentSources;
  paymentStatus: string;
  paidPeriodEnd: number;
};

export type TResponse = TGenericResponse<{
  result: TDetailedSubscription[];
}>;
