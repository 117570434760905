import Panel from "../../../../components/Panel";
import { PresentationProps } from "./TeamData.types";
import Text from "../../../../components/TypographyComponents/Text";
import Flex from "../../../../components/LayoutComponents/Flex";
import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
} from "../../../../components/FormComponents/Button";
import { ManageDomains } from "../ManageDomains";

export const TeamDataPresentation = (props: PresentationProps) => {
  const {
    team,
    isLoading,
    isManageDomainsActive,
    canRemoveTeam,
    canManageDomains,
    onToggleManageDomains,
    onRemoveTeam,
  } = props;
  return (
    <Panel>
      <Flex flexDirection="column" width="100%">
        <Text.Body3
          fontWeight={300}
          color="white"
          letterSpacing="3px"
          textTransform="uppercase"
        >
          you are a member of
        </Text.Body3>
        <Text.Body1>{team.title}</Text.Body1>
        {isManageDomainsActive ? (
          <ManageDomains />
        ) : (
          <>
            <Flex mt={3}>
              <Text.Body3
                fontWeight={300}
                color="white"
                letterSpacing="3px"
                textTransform="uppercase"
              >
                team domains:
              </Text.Body3>
            </Flex>
            <Text.Body2>
              {(team.teamDomains || []).map((data) => data.domain).join(", ") ||
                "-"}
            </Text.Body2>
          </>
        )}
        <Flex gap="10px" justifyContent="flex-end" mt={3}>
          {canManageDomains && (
            <Button
              variant={ButtonVariants.Outline}
              onClick={onToggleManageDomains}
              variantState={
                isLoading
                  ? ButtonVariantStates.Loading
                  : ButtonVariantStates.Idle
              }
            >
              {isManageDomainsActive
                ? `Close Domains Management`
                : `Manage Domains`}
            </Button>
          )}
          {canRemoveTeam && (
            <Button
              variant={ButtonVariants.Outline}
              onClick={onRemoveTeam(team.id)}
              variantState={
                isLoading
                  ? ButtonVariantStates.Loading
                  : ButtonVariantStates.Idle
              }
            >
              Remove Team
            </Button>
          )}
        </Flex>
      </Flex>
    </Panel>
  );
};
