import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import { TResponse } from "./types";
import { useRequestHandler } from "./useRequestHandler";
import { Mutations } from "../../common/mutations";
import { isSuccessResponse } from "../../../types/common/Api";
import { Queries } from "../../common/queries";

export const useRemoveDomain = () => {
  const { addToast } = useToasts();
  const handleRemoveDomain = useRequestHandler();
  const queryClient = useQueryClient();

  return useMutation<TResponse, unknown, string>(
    Mutations.RemoveDomain,
    (payload) => handleRemoveDomain(payload),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient
            .invalidateQueries(Queries.Teams)
            .catch((e) => console.error(e));
          return;
        }
        addToast("Error removing domain", {
          appearance: "error",
        });
      },
    }
  );
};
